import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { SliderItem } from "../schema";

const useSlider = (limit: number): [SliderItem[], boolean] => {
  const [sliderItems, setSliderItems] = useState<SliderItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  useEffect(() => {
    let cancelRequest = false;

    const fetchSlides = async () => {
      try {
        const response = await fetch(
          import.meta.env.VITE_GET_SLIDER_ITEMS + `${limit && `?limit=${limit}`}`
        );
        if (!response.ok) {
          throw new Error(
            `An error has occurred with error code: ${response.status} with an error message: ${response.statusText}`
          );
        }
        if (!cancelRequest) {
          const { data } = await response.json();
          setSliderItems(data);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        navigate(`/404`);
      }
    };
    fetchSlides();
    return () => {
      cancelRequest = true;
    };
  }, [limit]);
  return [sliderItems, isLoading];
};

export default useSlider;

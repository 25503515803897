export interface Props {
  color: string;
  hoverColor: string;
}

const Twitter = ({ color, hoverColor }: Props) => {
  return (
    <div className="w-6 h-6 sm:w-9 sm:h-9">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 36 36"
        className={`text-${color} fill-current hover:text-${hoverColor} w-full h-full`}
      >
        <path d="M20.912 15.588 32.303 2.25h-2.7l-9.889 11.58-7.901-11.58H2.7l11.948 17.514L2.7 33.75h2.7l10.444-12.231L24.19 33.75h9.112l-12.39-18.162Zm-3.697 4.329-1.21-1.744L6.371 4.298h4.147l7.771 11.198 1.21 1.743 10.105 14.558H25.46l-8.244-11.88Z" />
      </svg>
    </div>
  );
};

export default Twitter;

import { Helmet } from "react-helmet-async";
import BureauImageCard from "../../components/BureauImageCard";
import Image from "../../components/Image";
import usePageReset from "../../hooks/usePageReset";

const stats = [
  {
    number: "10,000+",
    description: "Hours of training for entrepreneurs, senior managers, and front line staff",
  },
  {
    number: "1500+",
    description: "Entrepreneurs passed Our previous Programs",
  },
  {
    number: "25/75",
    description: "The Ratio of Theory and Practice",
  },
  {
    number: "350+",
    description: "We teach people from 4 continents and over 40 countries",
  },
];

const servicesInformation = [
  {
    src: "/images/bureau/para.svg",
    alt: "bureau",
    description: "Soft-Landing",
    hoverText:
      "Cultiv Bureau provides comprehensive support to simplify your entry and ensure a smooth transition into the Egyptian market.",
  },
  {
    src: "/images/bureau/people.svg",
    alt: "bureau",
    description: "Outsourcing",
    hoverText: "Delegate routine tasks and access a cost-effective workforce in Egypt.",
  },
  {
    src: "/images/bureau/people-gear.svg",
    alt: "bureau",
    description: "Out-Staffing",
    hoverText:
      "Supplement your team with temporary or permanent Egyptian talent for specific needs.",
  },
  {
    src: "/images/bureau/puzzle.svg",
    alt: "bureau",
    description: "Project Teams",
    hoverText:
      "Get expert project based assistance for specific projects with our skilled Egyptian professionals.",
  },
  {
    src: "/images/bureau/magnifying-glass.svg",
    alt: "bureau",
    description: "Market Research",
    hoverText:
      "Gain valuable insights into the Egyptian market and your target audience through our tailored research solutions.",
  },
  {
    src: "/images/bureau/bitrix.svg",
    alt: "bureau",
    description: "Bitrix24",
    hoverText:
      "An all-in-one platform to manage your team and run your sales, it integrates into your business processes.",
  },
];

const Bureau = () => {
  const reset = usePageReset();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="keywords"
          content="outsourcing soft-landing business growth out-staffing tourism recruitment market-penetration"
        />
        <meta
          name="description"
          content="Cultiv Bureau: Expert outsourcing, out-staffing, and recruitment services tailored to boost your business efficiency and growth."
        />
        <meta name="robots" content="index, follow" />
        <title>Cultiv Bureau</title>
      </Helmet>

      <div className="flex flex-col gap-6 lg:gap-10 w-full">
        <section>
          <div className="bg-black px-5 sm:px-20 py-10 grid grid-cols-4 lg:grid-cols-12 gap-8 items-center">
            <div className="items-center justify-center col-span-4 lg:col-span-6">
              <a href="https://bureau.cultiv.llc/" target="_blank">
                <Image className="w-full" src="/images/bureau/Bureau-logo.svg" />
              </a>
            </div>
            <div className="col-span-4 lg:col-span-6 gap-5 lg:items-center justify-center text-center text-white">
              <h2 className="text-3xl lg:text-4xl xl:text-5xl xl:leading-[60px] font-medium pb-3 lg:pb-6">
                YOUR GATEWAY TO SUCCESS IN EGYPT
              </h2>
              <h3 className="lg:text-2xl lg:leading-9">
                Leverage Egypt's Workforce, Infrastructure & Thriving Market
              </h3>
            </div>
          </div>
        </section>
        <section className="px-6 lg:px-20">
          <div className="flex flex-col gap-2 lg:gap-3">
            <p className="font-semibold text-base md:text-2xl lg:text-3xl">
              Expanding your business to Egypt?
            </p>
            <div className="flex flex-col lg:flex-row gap-5 lg:gap-8">
              <p className="font-light text-sm md:text-xl lg:text-2xl md:leading-9 lg:leading-9 leading-6">
                Cultiv Bureau is your one-stop shop for a seamless soft-landing. We bridge the gap
                between your global aspirations and the immense potential of the Egyptian market.
              </p>
              <a
                href="https://bureau.cultiv.llc/"
                target="_blank"
                className="flex items-center justify-center self-center lg:self-end text-white hover:text-black bg-[#1E1E1E] hover:bg-cultiv-yellow h-10 lg:w-44 px-12"
              >
                <p className="text-sm sm:text-xl font-semibold whitespace-nowrap">Learn More</p>
              </a>
            </div>
          </div>
        </section>
        <section className="px-6 lg:px-20 xl:pt-5 xl:pb-5">
          <h3 className="flex justify-center lg:justify-start font-bold text-2xl md:text-3xl xl:text-5xl pb-5 lg:pb-10">
            What We Do
          </h3>
          <div className="flex flex-col lg:flex-row items-center lg:pb-5">
            <p className="order-2 lg:order-1 text-sm md:text-xl lg:text-2xl leading-6 lg:leading-9 lg:pr-8 pb-4 lg:pb-0 font-light">
              We empower businesses with comprehensive support that drives success. Our expert
              mentorship program offers 300 minutes of
              <strong className="font-semibold"> Guidance</strong> from seasoned industry leaders,
              helping you navigate challenges and refine your strategies.
              <br /> We connect you with top industry professionals
              <strong className="font-semibold"> Network</strong> in Egypt, facilitating valuable
              insights and relationship-building opportunities.
            </p>
            <Image
              className="order-1 lg:order-2 pb-4 lg:pb-0"
              src="/images/bureau/bureau1.png"
            ></Image>
          </div>
          <div className="flex flex-col lg:flex-row items-center">
            <Image className="pb-4 lg:pb-0" src="/images/bureau/bureau4.webp"></Image>
            <p className="text-sm md:text-xl lg:text-2xl leading-6 lg:leading-9 lg:pl-8 font-light">
              We enhance your <strong className="font-semibold">Market Research</strong> and
              strategy development through dedicated office hours and tailored workshops, ensuring
              you’re well-equipped to make informed decisions.
              <br /> We <strong className="font-semibold">Simplify Entry</strong> process providing
              discounted access to our infrastructure, significantly reducing operational costs and
              easing your soft-landing experience.
              <br />
              We give access to Egypt's highly skilled, multilingual
              <strong className="font-semibold"> Talent Pool</strong> through flexible outsourcing
              and dedicated project teams.
            </p>
          </div>
        </section>

        <section className="px-6 lg:px-20 lg:py-5 bg-black">
          <div className="h-full grid grid-cols-2 place-items-center lg:grid-cols-none lg:grid-flow-col-dense gap-x-8 lg:gap-4 lg:divide-x-2 divide-cultiv-grey">
            {stats.map(({ number, description }, index) => {
              return (
                <div
                  key={index}
                  className={`flex flex-col items-center h-full w-full lg:px-4 py-3 lg:py-0 text-center ${
                    index > 1 && "border-t-2 border-cultiv-grey lg:border-t-0"
                  } items-center gap-4 `}
                >
                  <h6 className="sm:text-5xl text-xl font-bold text-cultiv-yellow">{number}</h6>
                  <p className="sm:text-base lg:text-lg text-xs text-center text-white font-light max-w-[300px]">
                    {description}
                  </p>
                </div>
              );
            })}
          </div>
        </section>

        <section className="flex flex-col gap-6 lg:gap-10 lg:pt-5">
          <div className="flex flex-col text-center px-6 lg:px-20 lg:pb-5">
            <div className="flex flex-col text-center">
              <h3 className="flex justify-center lg:justify-start font-bold text-2xl md:text-3xl xl:text-5xl pb-5 lg:pb-10">
                Our Services
              </h3>
            </div>
            <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-8 lg:grid-cols-12 gap-4 lg:gap-7 min-h-[100px]">
              {servicesInformation.map(({ src, alt, description, hoverText }, index) => {
                return (
                  <BureauImageCard
                    key={index}
                    imgSrc={src}
                    imgAlt={alt}
                    cardText={description}
                    hoverText={hoverText}
                  />
                );
              })}
            </div>
          </div>
          <a
            href="https://bureau.cultiv.llc/contact"
            target="_blank"
            className="flex items-center justify-center self-center text-white hover:text-black bg-[#1E1E1E] hover:bg-cultiv-yellow h-10 lg:w-60 px-12"
          >
            <p className="text-sm sm:text-xl font-semibold whitespace-nowrap">Get in Touch</p>
          </a>
        </section>
      </div>
    </>
  );
};

export default Bureau;

export interface Props {
  textColor: string;
  className?: string;
}

const ShareBtn = ({ textColor, className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="20"
      className={`fill-current ${textColor} ${className}`}
      viewBox="0 0 17 20"
    >
      <path
        fillRule="evenodd"
        d="M10.965 6.511a3.333 3.333 0 10-.84-1.44L5.702 7.654a3.333 3.333 0 100 4.691l4.424 2.578a3.333 3.333 0 10.842-1.438l-4.426-2.58a3.336 3.336 0 000-1.812l4.423-2.58zM13.333 2.5a1.667 1.667 0 100 3.333 1.667 1.667 0 000-3.333zM4.75 9.12a.858.858 0 00.046.078 1.66 1.66 0 01-.024 1.639 1.666 1.666 0 01-3.105-.839A1.667 1.667 0 014.75 9.12zm6.917 6.711c0-.27.064-.527.179-.753a.843.843 0 00.102-.174 1.667 1.667 0 11-.282.927z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ShareBtn;

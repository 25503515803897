export const galleryImages: string[] = [
  "/images/cultivators/gallery/DSC01566.webp",
  "/images/cultivators/gallery/DSC01490.webp",
  "/images/cultivators/gallery/DSC01512.webp",
  "/images/cultivators/gallery/DSC01527.webp",
  "/images/cultivators/gallery/DSC01529.webp",
  "/images/cultivators/gallery/DSC01545.webp",
  "/images/cultivators/gallery/DSC01573.webp",
  "/images/cultivators/gallery/DSC01612.webp",
  "/images/cultivators/gallery/DSC01667.webp",
  "/images/cultivators/gallery/DSC01700.webp",
  "/images/cultivators/gallery/DSC01719.webp",
  "/images/cultivators/gallery/DSC01745.webp",
  "/images/cultivators/gallery/DSC01771.webp",
  "/images/cultivators/gallery/DSC01777.webp",
  "/images/cultivators/gallery/DSC01788.webp",
  "/images/cultivators/gallery/DSC01469.webp",
];

export const galleryImages2: string[] = [
  "/images/cultivators/panel2/_36A7757.webp",
  "/images/cultivators/panel2/_36A7765.webp",
  "/images/cultivators/panel2/_36A7767.webp",
  "/images/cultivators/panel2/_36A7775.webp",
  "/images/cultivators/panel2/_36A7789.webp",
  "/images/cultivators/panel2/_36A7796.webp",
  "/images/cultivators/panel2/_36A7812.webp",
  "/images/cultivators/panel2/_36A7823.webp",
  "/images/cultivators/panel2/_36A7825.webp",
  "/images/cultivators/panel2/_36A7828.webp",
  "/images/cultivators/panel2/_36A7849.webp",
  "/images/cultivators/panel2/_36A7875.webp",
  "/images/cultivators/panel2/_36A7954.webp",
  "/images/cultivators/panel2/_36A7878.webp",
  "/images/cultivators/panel2/_36A7959.webp",
  "/images/cultivators/panel2/_36A7978.webp",
];

import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router";
import { cultDetails as bio } from "../assets/componentDetails";
import { cultTabs } from "../assets/pageTabs";
import CultMembers from "../components/CultMembers";
import GridController from "../components/GridController";
import Header from "../components/Header";
import Team from "../components/Team";
import usePageReset from "../hooks/usePageReset";
import usePathname from "../hooks/usePathname";
import { Departments } from "../schema";

export default function Cult() {
  const { department } = useParams();
  const [displayed, setDisplayed] = useState<string>(
    (department as string) || Departments.principals
  );

  const chooseTab = useCallback(
    (id: string) => {
      setDisplayed(id);
    },
    [setDisplayed]
  );

  const { page } = usePathname();

  const pageReset = usePageReset();

  const navigate = useNavigate();

  useEffect(() => {
    if (page === "join") {
      setDisplayed(Departments.cultivators);
    } else {
      const departmentFound = Object.keys(Departments).includes(department as string);
      if (departmentFound) setDisplayed(department as string);
      if (!departmentFound && department) navigate(`/404`);
    }
  }, [department]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="keywords"
          content="people team principals entrepreneurs innovators cultiv cultivators"
        />
        <meta
          name="description"
          content="Cultiv is all about it's people our team and community is what makes us stand out the most"
        />
        <meta name="robots" content="index, follow" />
        <title>Cult</title>
      </Helmet>
      <div className="w-full flex flex-col justify-center items-center lg:gap-10 gap-6">
        <Header desc={bio.text} color="bg-cultiv-blue" textColor="white" />
        <div className="flex flex-col lg:gap-10 gap-4 justify-center items-center w-full lg:px-20 px-6">
          <GridController
            displayed={displayed}
            setDisplayed={chooseTab}
            tabs={cultTabs}
            page="cult"
          />
          {displayed === "team" ? (
            <Team displayed={displayed} />
          ) : (
            <CultMembers displayed={displayed} />
          )}
        </div>
      </div>
    </>
  );
}

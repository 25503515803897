import useTeam from "../hooks/useTeam";
import Loading from "./Loading";
import PersonGrid from "./PersonGrid";

export interface Props {
  displayed: string;
}

export default function Team({ displayed }: Props) {
  const [teamMembers, isLoading] = useTeam();

  return isLoading ? (
    <div className="min-h-[50vh] sm:min-h-[70vh] flex items-center justify-center">
      <Loading />
    </div>
  ) : (
    <PersonGrid members={teamMembers} displayed={displayed} />
  );
}

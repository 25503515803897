export interface Props {
  color: string;
  hoverColor: string;
}

const Linkedin = ({ color, hoverColor }: Props) => {
  return (
    <div>
      <svg
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`w-6 h-6 sm:h-9 sm:w-9 text-${color} fill-current hover:text-${hoverColor} }`}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.8335 1.45703C3.41725 1.45703 1.4585 3.41579 1.4585 5.83203C1.4585 8.24828 3.41725 10.207 5.8335 10.207C8.24974 10.207 10.2085 8.24828 10.2085 5.83203C10.2085 3.41579 8.24974 1.45703 5.8335 1.45703ZM4.37516 5.83203C4.37516 5.02662 5.02808 4.3737 5.8335 4.3737C6.63891 4.3737 7.29183 5.02662 7.29183 5.83203C7.29183 6.63745 6.63891 7.29036 5.8335 7.29036C5.02808 7.29036 4.37516 6.63745 4.37516 5.83203Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3335 10.207C20.6261 10.207 18.0295 11.2825 16.1151 13.197C14.2007 15.1114 13.1252 17.7079 13.1252 20.4154L13.1252 30.6237C13.1252 31.4291 13.7781 32.082 14.5835 32.082L20.4168 32.082C21.2222 32.082 21.8752 31.4291 21.8752 30.6237L21.8752 20.4154C21.8752 20.0286 22.0288 19.6577 22.3023 19.3842C22.5758 19.1107 22.9467 18.957 23.3335 18.957C23.7203 18.957 24.0912 19.1107 24.3647 19.3842C24.6382 19.6577 24.7918 20.0286 24.7918 20.4154L24.7918 30.6237C24.7918 31.4291 25.4447 32.082 26.2502 32.082L32.0835 32.082C32.8889 32.082 33.5418 31.4291 33.5418 30.6237L33.5418 20.4154C33.5418 17.7079 32.4663 15.1114 30.5519 13.197C28.6374 11.2825 26.0409 10.207 23.3335 10.207ZM23.3335 13.1237C21.3996 13.1237 19.545 13.8919 18.1775 15.2594C16.8101 16.6268 16.0418 18.4815 16.0418 20.4154L16.0418 29.1654L18.9585 29.1654L18.9585 20.4154C18.9585 19.255 19.4194 18.1422 20.2399 17.3218C21.0604 16.5013 22.1732 16.0404 23.3335 16.0404C24.4938 16.0404 25.6066 16.5013 26.4271 17.3218C27.2476 18.1422 27.7085 19.255 27.7085 20.4154L27.7085 29.1654L30.6252 29.1654L30.6252 20.4154C30.6252 18.4815 29.8569 16.6268 28.4895 15.2594C27.122 13.8919 25.2674 13.1237 23.3335 13.1237Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.4585 13.1237C1.4585 12.3183 2.11141 11.6654 2.91683 11.6654L8.75016 11.6654C9.55558 11.6654 10.2085 12.3183 10.2085 13.1237L10.2085 30.6237C10.2085 31.4291 9.55558 32.082 8.75016 32.082L2.91683 32.082C2.11141 32.082 1.4585 31.4291 1.4585 30.6237L1.4585 13.1237ZM4.37516 14.582L4.37516 29.1654L7.29183 29.1654L7.29183 14.582L4.37516 14.582Z"
        />
      </svg>
    </div>
  );
};

export default Linkedin;

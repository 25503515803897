import PodcastRSSFeed from "../pages/PodcastRSSFeed";
import { PodcastPlatform } from "../schema";
import PodcastPlatformLink from "./PodcastPlatformLink";

export interface Props {
  platformsArr: PodcastPlatform[];
}

const SubscribeToPodcast = ({ platformsArr }: Props) => {
  return (
    <div className="grid grid-cols-4 sm:grid-cols-12 gap-3 sm:gap-8">
      <div className="flex items-center col-span-4 sm:col-span-1">
        <p className="text-xs lg:text-lg font-semibold leading-5 pr-2">Subscribe:</p>
      </div>
      <div className="flex gap-8 col-start-2 col-span-11 pl-1">
        {platformsArr.map(({ src, url }, index) => {
          return <PodcastPlatformLink key={index} src={src} url={url} />;
        })}
        <PodcastRSSFeed />
      </div>
    </div>
  );
};

export default SubscribeToPodcast;

import { FormEvent, useState } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";
import usePageReset from "../../hooks/usePageReset";

type CultivatorsFormState = {
  name: string;
  email: string;
  number: string;
  company: string;
  jobTitle: string;
};

type CultivatorsFormStateError = {
  name: boolean;
  email: boolean;
  number: boolean;
  company: boolean;
  jobTitle: boolean;
};

const Register = () => {
  const [formState, setFormState] = useState<CultivatorsFormState>({
    name: "",
    email: "",
    number: "",
    company: "",
    jobTitle: "",
  });

  const [formErrors, setFormErrors] = useState<CultivatorsFormStateError>({
    name: false,
    email: false,
    number: false,
    company: false,
    jobTitle: false,
  });

  const pageReset = usePageReset();
  const navigate = useNavigate();

  const handleChange = (fieldName: string) => (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setFormState((prevState) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
    if (formErrors[fieldName as keyof CultivatorsFormStateError]) {
      setFormErrors((prevState) => {
        return {
          ...prevState,
          [fieldName]: false,
        };
      });
    }
  };

  const addAssignee = async () => {
    let response = await fetch(
      import.meta.env.VITE_POST_CULTIVATORS_SIGNUP_FORM +
        `name=${formState.name}&phone=${formState.number}&email=${formState.email}&company_name=${formState.company}&job_title=${formState.jobTitle}`,
      {
        method: "POST",
      }
    );
    let { data } = await response.json();
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const emptyInputs = Object.entries(formState).filter(
      ([key, val]) => key !== "comments" && !val
    );
    if (emptyInputs.length <= 0 && !isNaN(Number(formState.number))) {
      await addAssignee();
      toast.success("You are now a Cultivator!", {
        duration: 1500,
      });
      setFormState({
        name: "",
        email: "",
        number: "",
        company: "",
        jobTitle: "",
      });
      setTimeout(() => {
        navigate(`/culture/event-series/cultivators-forum`);
      }, 2000);
    } else {
      setFormErrors((prevState) => {
        return emptyInputs.reduce((acc, [key]) => {
          return {
            ...prevState,
            ...acc,
            [key]: true,
          };
        }, {} as CultivatorsFormStateError);
      });
    }
    if (isNaN(Number(formState.number)) || Number(formState.number.length) < 9) {
      setFormErrors((pervState) => {
        return {
          ...pervState,
          number: true,
        };
      });
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="keywords"
          content="cultiv cultivators entrepreneur startup members collective company consultation business growth idea"
        />
        <meta
          name="description"
          content="Cultivators, a community-based series of events to empower individuals around the globe to positively impact the future through cross-border networking, knowledge sharing, and mentorship."
        />
        <meta name="robots" content="index, follow" />
        <title>Cultivators Sign Up</title>
      </Helmet>
      <section className="md:bg-[url('/images/cultivators/globe.png')] bg-[url('/images/cultivators/cultivators-mobile-cover.svg')] bg-black bg-cover text-white min-h-max">
        <div className="md:bg-black md:bg-opacity-30 ">
          {isMobile && (
            <div className="w-full flex justify-center pb-5 pt-4 bg-black px-12">
              <img src="/images/cultivators/cultivators_register_logo.svg" alt="cultivators logo" />
            </div>
          )}
          <div className="flex md:flex-row flex-col md:pl-20 lg:pl-32 xl:pl-40 md:gap-5 lg:gap-52 lg:pr-28 xl:pr-[187px] px-7 lg:pb-12">
            <div className="flex flex-col md:w-1/2 pt-8 ">
              <h1 className="md:text-[32px] md:pt-0 pt-6 leading-8 font-medium md:pb-10 pb-3 md:text-start text-center text-xl">
                Become A Cultivator
              </h1>
              <form
                method="POST"
                id="cultivators-form"
                className="flex flex-col flex-1 md:justify-between gap-5"
                onSubmit={handleSubmit}
              >
                <div className="flex flex-col gap-1.5">
                  <label
                    htmlFor="name"
                    className="md:text-xl text-base font-mono md:font-medium font-normal"
                  >
                    Name:
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className={`py-2.5 px-3 text-black focus:outline-none border-3 focus:border-cultiv-yellow ${
                      formErrors.name ? "border-red-600" : "border-[#CBD2E0]"
                    }`}
                    value={formState.name}
                    onChange={handleChange("name")}
                    placeholder="John Doe"
                  />
                  <div className="h-1">
                    {formErrors.name && (
                      <p className="text-red-600 text-xs font-bold">This field is required</p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-1.5">
                  <label
                    htmlFor="email"
                    className="md:text-xl text-base font-mono md:font-medium font-normal"
                  >
                    Email:
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className={`py-2.5 px-3 text-black focus:outline-none border-3 focus:border-cultiv-yellow ${
                      formErrors.email ? "border-red-600" : "border-[#CBD2E0]"
                    }`}
                    value={formState.email}
                    onChange={handleChange("email")}
                    placeholder="example@email.com"
                  />
                  <div className="h-1">
                    {formErrors.email && (
                      <p className="text-red-600 text-xs font-bold">This field is required</p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-1.5">
                  <label
                    htmlFor="number"
                    className="md:text-xl text-base font-mono md:font-medium font-normal"
                  >
                    Phone Number:
                  </label>
                  <input
                    type="text"
                    name="number"
                    id="number"
                    className={`py-2.5 px-3 text-black focus:outline-none border-3 focus:border-cultiv-yellow ${
                      formErrors.number ? "border-red-600" : "border-[#CBD2E0]"
                    }`}
                    value={formState.number}
                    onChange={handleChange("number")}
                    placeholder="+1(123)456-789"
                  />
                  <div className="h-1">
                    {formErrors.number && (
                      <p className="text-red-600 text-xs font-bold">
                        This field is required or phone number is invalid
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-1.5">
                  <label
                    htmlFor="company"
                    className="md:text-xl text-base font-mono md:font-medium font-normal"
                  >
                    Company:
                  </label>
                  <input
                    type="text"
                    name="company"
                    id="company"
                    className={`py-2.5 px-3 text-black focus:outline-none border-3 focus:border-cultiv-yellow ${
                      formErrors.company ? "border-red-600" : "border-[#CBD2E0]"
                    }`}
                    value={formState.company}
                    onChange={handleChange("company")}
                    placeholder="Cultiv"
                  />
                  <div className="h-1">
                    {formErrors.company && (
                      <p className="text-red-600 text-xs font-bold">This field is required</p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-1.5">
                  <label
                    htmlFor="job-title"
                    className="md:text-xl text-base font-mono md:font-medium font-normal"
                  >
                    Job Title:
                  </label>
                  <input
                    type="text"
                    name="jobTitle"
                    id="job-title"
                    className={`py-2.5 px-3 text-black focus:outline-none border-3 focus:border-cultiv-yellow ${
                      formErrors.jobTitle ? "border-red-600" : "border-[#CBD2E0]"
                    }`}
                    value={formState.jobTitle}
                    onChange={handleChange("jobTitle")}
                    placeholder="Business Analyst"
                  />
                  <div className="h-1">
                    {formErrors.jobTitle && (
                      <p className="text-red-600 text-xs font-bold">This field is required</p>
                    )}
                  </div>
                </div>
                <div className=" self-center md:pb-10 lg:pb-0 pb-10 pt-7">
                  <button
                    type="submit"
                    className="border-2 md:text-xl ease-in-out duration-300 text-base text-white md:font-bold font-semibold border-white md:w-60 w-32 py-2 pt-3 hover:bg-cultiv-yellow hover:text-black hover:border-cultiv-yellow"
                    form="cultivators-form"
                  >
                    Sign Me Up
                  </button>
                </div>
              </form>
            </div>
            {!isMobile && (
              <div className=" py-10 flex flex-col items-center justify-center">
                <div className="flex flex-col items-center">
                  <div className="md:w-2/3 lg:w-full flex justify-center pb-10">
                    <img
                      src="/images/cultivators/cultivators_register_logo.svg"
                      alt="cultivators logo"
                    />
                  </div>
                  <div className="flex md:max-w-md lg:max-w-full">
                    <img src="/images/cultivators/cultivators_icon.svg" alt="world-image" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Toaster />
      </section>
    </>
  );
};

export default Register;

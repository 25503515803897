import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router";
import { cultureDetails as bio } from "../assets/componentDetails";
import { cultivatorsEventPanels } from "../assets/cultivatorsEventPanels";
import { cultureTabs as tabs } from "../assets/pageTabs";
import CardsGrid from "../components/CardsGrid";
import GridController from "../components/GridController";
import Header from "../components/Header";
import Loading from "../components/Loading";
import PodcastSeriesGrid from "../components/PodcastSeriesGrid";
import useHomeFeed from "../hooks/useHomeFeed";
import usePageReset from "../hooks/usePageReset";

export default function Culture() {
  const { cultureTab } = useParams();
  const [displayed, setDisplayed] = useState<string>((cultureTab as string) || "article");
  const navigate = useNavigate();
  const chooseTab = useCallback(
    (id: string) => {
      setDisplayed(id);
    },
    [setDisplayed]
  );

  // const [fetchedCards, isLoading] = useCultureTabs(displayed);
  const [fetchedCards, isLoading] = useHomeFeed(undefined, displayed);
  const [gridCards, setGridCards] = useState(fetchedCards);

  useEffect(() => {
    const tabFound = tabs.find((tab) => tab.id === cultureTab);
    if (tabFound) setDisplayed(cultureTab as string);
    if (!tabFound && cultureTab) navigate(`/404`);
  }, [cultureTab, setDisplayed]);

  useEffect(() => {
    setGridCards(fetchedCards);
    if (displayed === "event") {
      setGridCards((prevGridCards) => {
        const combinedCards = [...prevGridCards, ...cultivatorsEventPanels];
        return combinedCards;
      });
    }
  }, [fetchedCards]);

  const pageReset = usePageReset();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="events articles posts news" />
        <meta
          name="description"
          content="Find all the news about Cultiv, the information about our events and interesting articles that will help you grow as entrepreneur"
        />
        <meta name="robots" content="index, follow" />
        <title>Culture</title>
      </Helmet>

      <div className="w-full flex flex-col justify-center items-center lg:gap-10 gap-6">
        <Header desc={bio.text} color="bg-cultiv-red" />
        <div className="w-full flex flex-col lg:gap-10 gap-6 justify-center items-center lg:px-20 px-6">
          <GridController
            displayed={displayed}
            setDisplayed={chooseTab}
            tabs={tabs}
            page="culture"
          />
          {isLoading ? (
            <div className="min-h-[50vh] sm:min-h-[70vh] flex items-center justify-center">
              <Loading />
            </div>
          ) : displayed !== "podcasts" ? (
            <CardsGrid cards={gridCards} />
          ) : (
            <PodcastSeriesGrid />
          )}
        </div>
      </div>
    </>
  );
}

const MarqueeText = () => {
  return (
    <div className="flex items-center">
      <div className="w-1.5 h-1.5 md:w-2.5 md:h-2.5 bg-white rounded-full"></div>
      <div className="text-xs md:text-xl px-2.5 md:py-5 py-2">Coming soon</div>
    </div>
  );
};

export default MarqueeText;

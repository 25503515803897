import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { PodcastEpisode } from "../schema";

type usePodcastOutput = {
  isLoading: boolean;
  seriesEpisodes: PodcastEpisode[];
};

const usePodcast = (podcastID: string): usePodcastOutput => {
  const [episodes, setEpisodes] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    let cancelRequest = false;
    const fetchEpisodes = async (podcastID: string) => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `https://posts.bb.cultiv.llc/api/temp-podcast?path=podcast/${podcastID}`
        );
        if (!response.ok) {
          throw new Error("An Error has occurred while fetching series episodes");
        }
        if (!cancelRequest) {
          const data = await response.json();

          setEpisodes(data);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error, "this series doesn't exist or has been deleted");
        navigate(`/404`);
      }
    };
    if (podcastID === "radio-elafkar") {
      fetchEpisodes(podcastID);
    } else {
      navigate(`/404`);
    }
    return () => {
      cancelRequest = true;
    };
  }, [podcastID]);

  return {
    isLoading: isLoading,
    seriesEpisodes: episodes,
  };
};

export default usePodcast;

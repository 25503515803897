import { Helmet } from "react-helmet-async";
import AboutPageIcon from "../components/Icons/AboutPageIcon";
import usePageReset from "../hooks/usePageReset";
import structuredData from "../assets/structuredText";

export default function About() {
  const pageReset = usePageReset();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="cultiv" />
        <meta name="description" content="Know all about Cultiv LLC" />
        <meta name="robots" content="index, follow" />
        <meta property="og:url" content="https://staging.cultiv.llc" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          itemProp="image"
          content="https://staging.cultiv.llc/images/metaimg.jpg"
        />
        <link
          rel="image_src"
          itemProp="image"
          href="https://staging.cultiv.llc/images/metaimg.jpg"
        />
        <meta
          property="og:description"
          content="The staging platform of cultiv.llc website | About page"
        />
        <meta property="og:site_name" content="Cultiv LLC" />
        <meta property="og:title" content="Staging Cultiv LLC" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://staging.cultiv.llc" />
        <meta property="twitter:title" content="Staging Cultiv" />
        <meta property="twitter:description" content="This the about page cultiv staging website" />
        <meta property="twitter:image" content="https://staging.cultiv.llc/images/metaimg.jpg" />
        <link rel="canonical" href="https://staging.cultiv.llc" />
        <script type="application/ld+json">{structuredData}</script>
        <title>About Cultiv</title>
      </Helmet>
      <div className="w-full bg-black">
        <AboutPageIcon />
      </div>
      <div className="flex flex-col justify-center items-start px-6 lg:px-20 lg:pt-10 pt-6">
        <h1 className="md:text-2xl lg:text-4xl text-xl font-bold text-center lg:pb-6 pb-3">
          Who are we ?
        </h1>
        <div className="flex flex-col lg:gap-6 gap-3 text-sm leading-6 lg:text-2xl lg:leading-10 md:text-lg md:leading-9">
          <p>
            Cultiv is a platform for supporting innovation through venture building, networking and
            community building. Our mission is to empower entrepreneurs, innovators, technologists
            and designers in Europe and the MENA region. We do this through the continuous
            development of a global network of experts, professionals and investors. Our members
            operate in a number of activity areas, including venture building, technology product
            development, incubation/acceleration of startups, international technology and
            innovation competitions, venture capital, turnkey business solutions for SMEs, global
            market access, and operating co-working and innovation spaces. We are connected with an
            extensive network of clusters, tech hubs, universities, government agencies and other
            organizations in the areas of deep-tech and ICT, in Europe and the MENA region, with a
            special focus in the areas of AI, software, design, fintech, semiconductors,
            electronics, hardware, biotech, cybersecurity, digital transformation, agri-tech,
            cleantech, climate and travel.
          </p>
        </div>
      </div>
    </>
  );
}

export interface Props {
  className: string;
}

const SkipForwardIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={`fill-current ${className}`}
    >
      <path d="M7.163 3.258a10 10 0 0111.89 1.66L22 7.64V4a1 1 0 112 0v6a1 1 0 01-1 1h-6a1 1 0 110-2h3.526l-2.844-2.625a8 8 0 101.865 8.293 1 1 0 011.886.664A10 10 0 117.163 3.258z"></path>
      <path d="M7.632 15V9.544l.352.272H6.336V9.16H8.4V15h-.768zm4.703.144c-.437 0-.81-.075-1.12-.224a2.074 2.074 0 01-.744-.624 2.638 2.638 0 01-.408-.904 4.57 4.57 0 01-.128-1.096v-.432c0-.539.083-1.024.248-1.456.171-.432.432-.773.784-1.024.358-.256.814-.384 1.368-.384.555 0 1.008.128 1.36.384.352.25.611.592.776 1.024.171.432.256.917.256 1.456v.432c0 .384-.042.75-.128 1.096a2.557 2.557 0 01-.416.904c-.186.261-.432.47-.736.624-.304.15-.674.224-1.112.224zm0-.704c.555 0 .96-.187 1.216-.56.262-.379.392-.976.392-1.792 0-.853-.133-1.464-.4-1.832-.261-.368-.664-.552-1.208-.552-.549 0-.957.184-1.224.552-.261.368-.392.973-.392 1.816 0 .821.131 1.421.392 1.8.262.379.67.568 1.224.568z"></path>
    </svg>
  );
};

export default SkipForwardIcon;

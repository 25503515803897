import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router";
import { collectiveDetails as bio } from "../assets/componentDetails";
import { collectiveTabs } from "../assets/pageTabs";
import CompanyGrid from "../components/CompanyGrid";
import GridController from "../components/GridController";
import Header from "../components/Header";
import Loading from "../components/Loading";
import usePageReset from "../hooks/usePageReset";
import useRelation from "../hooks/useRelation";
import { CollectiveCompanies } from "../schema";

export default function Collective() {
  const { companyType } = useParams();
  const [displayed, setDisplayed] = useState<string>((companyType as string) || "members");
  const navigate = useNavigate();
  const chooseTab = useCallback(
    (id: string) => {
      setDisplayed(id);
    },
    [setDisplayed]
  );
  const pageReset = usePageReset();

  useEffect(() => {
    const typeFound = Object.keys(CollectiveCompanies).includes(companyType as string);
    if (typeFound) setDisplayed(companyType as string);
    if (!typeFound && companyType) navigate(`/404`);
  }, [companyType]);
  const [selectedTab, isLoading] = useRelation(displayed == "members" ? "collective" : "partner");
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="members collective companies cultiv" />
        <meta name="description" content="Cultiv is a collective of companies that work together" />
        <meta name="robots" content="index, follow" />
        <title>Collective</title>
      </Helmet>
      <div className="w-full flex flex-col justify-center items-center lg:gap-10 gap-6">
        <Header desc={bio.text} color="bg-cultiv-yellow" />
        <div
          id="members"
          className="flex flex-col lg:gap-10 gap-6 w-full items-center justify-center lg:px-20 px-6"
        >
          <GridController
            tabs={collectiveTabs}
            displayed={displayed}
            setDisplayed={chooseTab}
            page="collective"
          />
          {isLoading ? (
            <div className="min-h-[50vh] sm:min-h-[70vh] flex items-center justify-center">
              <Loading />
            </div>
          ) : (
            <CompanyGrid cards={selectedTab} />
          )}
        </div>
      </div>
    </>
  );
}

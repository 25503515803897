import {
  PauseCircleIcon,
  PlayCircleIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import SkipBackwardIcon from "./Icons/SkipBackwardIcon";
import SkipForwardIcon from "./Icons/SkipForwardIcon";
import ShareBtn from "./Icons/ShareBtn";
import toast, { Toaster } from "react-hot-toast";

export interface Props {
  /**
   * Url to an audio file to render
   */
  episode: string;
  /**
   * Tailwind styles for the parent div of the audio player
   */
  className?: string;
  /**
   * add the color required to fill player icons in the form of text color class ex: text-white
   */
  fillIcons?: string;
  /**
   * add the color required to hover player icons in the form of text color class ex: text-white
   */
  hoverFillIcons?: string;
  /**
   * add the color of the drawn wave in hex ex: #fff
   */
  waveColor?: string;
  /**
   * add the color of the progress wave in hex ex: #fff
   */
  progressColor?: string;
  /**
   * add the required wave height
   */
  waveHeight?: number;
  /**
   * add the color of the numbers in the form of a text utility class from tailwind ex: text-gray-50
   */
  timerTextColor?: string;

  /**
   * Should the share button to share episode link be visible or not | Default value is false
   */
  shareEpisode?: boolean;

  /**
   * Episode slug to share the episode page
   */
  episodePageSlug?: string | null;
}

const WaveformAudioPlayer = ({
  episode,
  className,
  fillIcons,
  hoverFillIcons,
  waveColor,
  progressColor,
  timerTextColor,
  waveHeight,
  shareEpisode = false,
  episodePageSlug = null,
}: Props) => {
  const waveformRef = useRef<HTMLDivElement | null>(null);
  const waveSurfer = useRef<WaveSurfer | null>(null);
  const timerRef = useRef<number | undefined>();
  const playTimeRef = useRef<number | null>(null);
  const minutesRef = useRef<HTMLParagraphElement | null>(null);
  const secondsRef = useRef<HTMLParagraphElement | null>(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const [muteState, setMuteState] = useState<boolean>(false);
  const [fullTime, setFullTime] = useState<string>("00:00");

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      if (waveformRef.current) {
        waveSurfer.current = WaveSurfer.create({
          container: waveformRef.current as HTMLElement,
          autoplay: false,
          waveColor: `${waveColor ? waveColor : "#b3b3b3"}`,
          progressColor: `${progressColor ? progressColor : "#000"}`,
          height: waveHeight || 46,
          barGap: 3,
          barWidth: 2,
          cursorWidth: 0,
        });
        waveSurfer.current.load(episode);
      }
      if (minutesRef.current) minutesRef.current.innerHTML = "00";
      if (secondsRef.current) secondsRef.current.innerHTML = "00";
      waveSurfer.current?.on("ready", () => {
        let fullMinutes: string;
        let fullSeconds: string;
        const duration = waveSurfer.current?.getDuration();
        const minutes = Math.floor((duration || 0) / 60);
        const seconds = Math.floor((duration || 0) - minutes * 60);
        if (minutes > 10) {
          fullMinutes = minutes.toString();
        } else {
          fullMinutes = `0${minutes}`;
        }
        if (seconds > 10) {
          fullSeconds = seconds.toString();
        } else {
          fullSeconds = `0${seconds}`;
        }
        const fullDuration = `${fullMinutes}:${fullSeconds}`;
        setFullTime(fullDuration);
      });
    });

    return () => {
      clearTimeout(timerRef.current);

      waveSurfer.current?.destroy();
    };
  }, []);

  const calculatePlayTime = () => {
    clearInterval(playTimeRef.current as number | undefined);
    playTimeRef.current = setInterval(() => {
      const currentTime = waveSurfer.current?.getCurrentTime();
      const minutes = Math.floor((currentTime || 0) / 60);
      const seconds = Math.floor((currentTime || 0) - minutes * 60);
      if (minutesRef.current) {
        if (minutes < 10) {
          minutesRef.current.innerHTML = `0${minutes}`;
        } else {
          minutesRef.current.innerHTML = `${minutes}`;
        }
      }

      if (secondsRef.current) {
        if (seconds < 10) {
          secondsRef.current.innerHTML = `0${seconds}`;
        } else {
          secondsRef.current.innerHTML = `${seconds}`;
        }
      }
    }, 1000);
  };
  const stopPlayTime = () => {
    clearInterval(playTimeRef.current as number | undefined);
  };

  const pausePLay = () => {
    setPlaying(!playing);
    waveSurfer.current?.playPause();
  };

  const skipBackward = () => {
    waveSurfer.current?.skip(-10);
  };

  const skipForward = () => {
    waveSurfer.current?.skip(10);
  };

  const toggleMute = () => {
    waveSurfer.current?.setMuted(!muteState);
    setMuteState(!muteState);
  };

  const onShare = async () => {
    await navigator.clipboard.writeText(
      `https://cultiv.llc/culture/${
        episodePageSlug ? "podcast/episode/episodePageSlug" : "podcasts"
      }`
    );
    toast.success("Link copied to clipboard", {
      className: "text-[#808080]",
    });
  };

  return (
    <div className={`flex ${className}`}>
      <div className="flex">
        <button onClick={skipBackward}>
          <SkipBackwardIcon
            className={` size-6 ${fillIcons ? `${fillIcons}` : "text-[#808080]"} ${
              hoverFillIcons ? `hover:${hoverFillIcons}` : "hover:text-black"
            }  `}
          />
        </button>

        <button
          onClick={() => {
            pausePLay();
            playing ? stopPlayTime() : calculatePlayTime();
          }}
        >
          {playing ? (
            <PauseCircleIcon
              className={` size-8 sm:size-11  ${fillIcons ? `${fillIcons}` : "text-[#808080]"} ${
                hoverFillIcons ? `hover:${hoverFillIcons}` : "hover:text-black "
              }`}
            />
          ) : (
            <PlayCircleIcon
              className={` size-8 sm:size-11 ${fillIcons ? `${fillIcons}` : "text-[#808080]"} ${
                hoverFillIcons ? `hover:${hoverFillIcons}` : "hover:text-black"
              }`}
            />
          )}
        </button>

        <button onClick={skipForward}>
          <SkipForwardIcon
            className={`size-6  ${fillIcons ? `${fillIcons}` : "text-[#808080]"} ${
              hoverFillIcons ? `hover:${hoverFillIcons}` : "hover:text-black"
            } `}
          />
        </button>
      </div>
      <div
        className={`flex items-center text-2xs  sm:text-sm ${
          timerTextColor && `${timerTextColor}`
        } justify-center`}
      >
        <p className="h-full flex justify-center items-center" ref={minutesRef} />
        :
        <p className="h-full flex justify-center items-center" ref={secondsRef} />
      </div>
      <div className="flex items-center w-full min-h-full">
        <div ref={waveformRef} className="w-full" />
      </div>
      <div className="flex gap-1.5">
        <div
          className={`flex items-center text-xs sm:text-sm ${
            timerTextColor && `${timerTextColor}`
          } justify-center`}
        >
          <p className="h-full flex justify-center items-center">{fullTime}</p>
        </div>

        <button className="flex items-center justify-center" onClick={toggleMute}>
          {muteState ? (
            <SpeakerXMarkIcon
              className={`size-4 2xl:size-6   ${fillIcons ? `${fillIcons}` : "text-[#808080]"} ${
                hoverFillIcons ? `hover:${hoverFillIcons}` : "hover:text-black"
              } `}
            />
          ) : (
            <SpeakerWaveIcon
              className={`size-4 2xl:size-6   ${fillIcons ? `${fillIcons}` : "text-[#808080]"} ${
                hoverFillIcons ? `hover:${hoverFillIcons}` : "hover:text-black"
              } `}
            />
          )}
        </button>
        {shareEpisode && (
          <button className="flex items-center justify-center" onClick={onShare}>
            <ShareBtn textColor="hover:text-black text-[#808080]" className="size-4 2xl:size-6" />
          </button>
        )}
      </div>
      <Toaster />
    </div>
  );
};

export default WaveformAudioPlayer;

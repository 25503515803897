import { configureStore } from "@reduxjs/toolkit";
import navMenuReducer from "./features/navMenuSlice";
import drawerControllerReducer from "./features/mobileDrawerSlice";
import cultivatorsImageModalReducer from "./features/cultivatorsImageModalSlice";
import memberModalReducer from "./features/memberModalSlice";
import pagePaginationReducer from "./features/pagePaginationSlice";

export const store = configureStore({
  reducer: {
    navMenu: navMenuReducer,
    drawerController: drawerControllerReducer,
    cultivatorsImageModal: cultivatorsImageModalReducer,
    memberModal: memberModalReducer,
    pagePagination: pagePaginationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

import { Helmet } from "react-helmet-async";
import usePageReset from "../hooks/usePageReset";

export default function Careers() {
  const pageReset = usePageReset();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="job opportunity vacancy cultiv" />
        <meta name="description" content="Join our creative team" />
        <meta name="robots" content="index, follow" />
        <title>Cultiv | Careers</title>
      </Helmet>
      <div className="flex flex-col md:flex-row items-center md:justify-between lg:pt-14 pt-6 md:px-10 lg:px-20 px-6">
        <div className="flex flex-col gap-16">
          <div className="flex flex-col lg:gap-10 gap-6">
            <h1 className="font-bold text-2xl md:text-4xl md:text-start text-center">
              Join our team and become one of us!
            </h1>
            <div className="flex flex-col lg:flex-row lg:gap-8 gap-6">
              <img
                src="/images/careerIcon.png"
                className="object-contain lg:order-2 order-1"
                alt="Join the cultivators"
                height={387}
                width={433}
              />
              <div className="flex flex-col lg:order-1 order-2">
                <p className="md:text-2xl text-sm leading-6 md:leading-9 lg:leading-10  text-justify pb-6 xl:pl-20">
                  Cultiv has core values that impact its culture and strategy and build a sense of
                  commitment. The company empowers its employees by encouraging innovation and
                  emphasizing that small changes today lead to massive changes tomorrow. Our work
                  relations are based on respect and integrity and embrace diversity. Do you want to
                  be part of our team, leading the change and leaving an impact everywhere?
                </p>
                <h2 className="md:text-2xl text-sm pb-3 lg:pl-20">Email us now at:</h2>
                <a
                  className="font-bold md:text-2xl text-lg hover:underline self-center"
                  href="mailto:careers@cultiv.llc"
                >
                  careers@cultiv.llc
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { Transition } from "@headlessui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useTextTruncation from "../hooks/useTextTruncation";
import Image from "./Image";
import ReadMoreAnime from "./ReadMoreAnime";

export interface Props {
  title: string;
  imgSrc: string;
  desc: string;
  slug: string;
  cycles: number;
}

export default function ProgramCard({ title, imgSrc, desc, slug, cycles }: Props) {
  const [isInside, setIsInside] = useState<boolean>(false);
  const truncatedText = useTextTruncation(desc, 130);
  const navigate = useNavigate();
  return (
    <div
      className={`border-2 border-black border-opacity-30 w-full aspect-square relative flex items-end justify-end`}
      onMouseEnter={() => {
        setIsInside(true);
      }}
      onMouseLeave={() => {
        setIsInside(false);
      }}
    >
      <Image src={imgSrc} alt={title} className="absolute top-0 left-0 w-full h-full -z-10" />

      <Transition
        show={isInside}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="absolute top-0 left-0 z-20 h-full w-full text-white"
      >
        <div
          onClick={() => {
            navigate(`/cultivation/program/${slug}`);
          }}
          className="bg-cultiv-sky justify-between text-black flex flex-1 flex-col items-start px-6 pt-6 pb-3 h-full w-full cursor-pointer"
        >
          <div className="flex flex-col justify-start items-start gap-1">
            <h1 className="font-bold text-xl md:text-2xl lg:text-lg xl:text-xl 2xl:text-3xl pb-5 lg:pb-1 2xl:pb-5">
              {title}
            </h1>
          </div>
          <p className="3xl:text-2xl 2xl:text-base xl:text-sm lg:text-sm md:text-lg text-base xl:line-clamp-5 2xl:line-clamp-none">
            {truncatedText}
          </p>
          <button className="w-full">
            <ReadMoreAnime color="black" />
          </button>
        </div>
      </Transition>
    </div>
  );
}

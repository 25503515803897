import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../redux/configureStore";
import { close } from "../redux/features/mobileDrawerSlice";
import { findMenuStateById, hideMenu, reverseMenu, showMenu } from "../redux/features/navMenuSlice";
import { SubLink } from "../schema";
import RightChevron from "./Icons/RightChevron";
export interface Props {
  id: string;
  dropdownItems: SubLink[];
  pathname: string;
  to: string;
}

export default function NavDropDownMenu({ dropdownItems, id, pathname, to }: Props) {
  const shouldOpen = useSelector((state: RootState) => findMenuStateById(state.navMenu, id));
  const dispatch = useDispatch();
  const hide = () => {
    dispatch(hideMenu(id));
  };
  const reverse = () => {
    dispatch(reverseMenu(id));
  };
  const show = () => {
    dispatch(showMenu(id));
  };
  const closeDrawer = () => {
    dispatch(close());
  };

  return (
    <Menu as="div" className="md:inline-block w-full flex justify-end flex-col items-end z-50">
      <Menu.Button
        className={`h-7 w-7 self-end absolute top-0 sm:static flex items-center justify-center ${
          pathname === id ? "text-cultiv-yellow z-10" : "text-gray-200"
        }`}
        aria-label="SubLinks Menu Button"
        onClick={reverse}
      >
        <RightChevron color="white" className="rotate-90" />
      </Menu.Button>
      <Transition
        show={shouldOpen}
        as={Fragment}
        enter="transition ease-out duration-100 "
        enterFrom="transform opacity-0 scale-95 "
        enterTo="transform opacity-100 scale-100 "
        leave="transition ease-in duration-75 "
        leaveFrom="transform opacity-100 scale-100 "
        leaveTo="transform opacity-0 scale-95 "
      >
        <Menu.Items
          onMouseEnter={show}
          onMouseLeave={hide}
          className="p-1 md:absolute md:right-0 md:mt-2 w-full md:min-w-max bg-black origin-top-right md:shadow-lg md:ring-1 md:ring-white  md:ring-opacity-5 focus:outline-none"
        >
          <div className="px-1 relative py-1 ">
            {dropdownItems.map((item) => {
              return (
                <Menu.Item key={item.label}>
                  {({ active }) => (
                    <Link
                      to={`${item.href}${item.hash}`}
                      onClick={() => {
                        hide();
                        isMobile && closeDrawer();
                      }}
                      className={`text-gray-200 hover:text-cultiv-yellow flex w-full items-center px-2 py-2 font-semibold md:text-sm xl:text-lg`}
                    >
                      <h2>{item.label}</h2>
                    </Link>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

import { isMobile } from "react-device-detect";

const AboutPageIcon = () => {
  if (isMobile) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 393 153">
        <path fill="#000" d="M0 0H393V152H0z"></path>
        <path
          fill="#fff"
          d="M45.233 105.48c-2.48 0-4.64-.6-6.48-1.8-1.84-1.2-3.28-2.88-4.32-5.04-1.013-2.187-1.52-4.72-1.52-7.6 0-2.933.52-5.48 1.56-7.64 1.067-2.16 2.547-3.827 4.44-5 1.894-1.2 4.107-1.8 6.64-1.8 2.027 0 3.88.413 5.56 1.24a9.968 9.968 0 014.12 3.52c1.067 1.547 1.64 3.413 1.72 5.6h-8.08c-.106-1.12-.453-2.067-1.04-2.84-.56-.773-1.36-1.16-2.4-1.16-.907 0-1.693.307-2.36.92-.666.613-1.187 1.52-1.56 2.72-.346 1.173-.52 2.653-.52 4.44 0 1.867.187 3.4.56 4.6.373 1.173.88 2.04 1.52 2.6s1.36.84 2.16.84c.72 0 1.347-.16 1.88-.48.534-.347.96-.827 1.28-1.44.347-.64.56-1.387.64-2.24h8.08c-.213 2.133-.8 4-1.76 5.6-.96 1.573-2.28 2.8-3.96 3.68-1.68.853-3.733 1.28-6.16 1.28zm24.536 0c-2.48 0-4.6-.44-6.36-1.32-1.734-.88-3.067-2.12-4-3.72-.907-1.627-1.36-3.533-1.36-5.72V77.08h7.88v17.84c0 .853.146 1.6.44 2.24a3.54 3.54 0 001.36 1.44c.586.32 1.266.48 2.04.48.826 0 1.52-.16 2.08-.48.56-.347 1-.827 1.32-1.44.32-.64.48-1.387.48-2.24V77.08h7.88v17.64c0 2.187-.467 4.093-1.4 5.72-.907 1.6-2.24 2.84-4 3.72-1.734.88-3.854 1.32-6.36 1.32zm14.615-.48V77.08h7.88V98.6h13.04v6.4h-20.92zm31.096 0V83.44h-7.72v-6.36h23.32v6.36h-7.72V105h-7.88zm24.776 0V77.08h7.88V105h-7.88zm-5.96-27.92h19.8v6.36h-19.8v-6.36zm0 21.52h19.8v6.4h-19.8v-6.4zm29.055 6.4l-6.36-27.92h7.88l3.2 14.92 1.04 7.12 1-7.12 3.24-14.92h7.84l-6.44 27.92h-11.4z"
        ></path>
        <path fill="#2424C9" d="M373.056 5.684H393.42499999999995V26.053H373.056z"></path>
        <path fill="#2424C9" d="M299.16 76.262H352.687V98.05199999999999H299.16z"></path>
        <path fill="#2424C9" d="M373.056 46.422H393.42499999999995V66.791H373.056z"></path>
        <path fill="#2424C9" d="M373.056 87.16H393.42499999999995V107.529H373.056z"></path>
        <path fill="#2424C9" d="M373.056 127.895H393.42499999999995V148.264H373.056z"></path>
        <path fill="#EC494A" d="M352.687 107.527H373.05600000000004V127.896H352.687z"></path>
        <path
          fill="#E55CB4"
          d="M329.95 138.637H341.792V150.479H329.95z"
          transform="rotate(-45 329.95 138.637)"
        ></path>
        <path fill="#EC494A" d="M299.16 98.055H325.213V121.266H299.16z"></path>
        <path
          fill="#EC494A"
          d="M352.687 148.266H373.05600000000004V152.05599999999998H352.687z"
        ></path>
        <path fill="#EC494A" d="M352.687 66.793H373.05600000000004V87.162H352.687z"></path>
        <path fill="#EC494A" d="M185.474 32.688H205.843V53.057H185.474z"></path>
        <path fill="#EC494A" d="M352.687 26.055H373.05600000000004V46.424H352.687z"></path>
        <path fill="#EC494A" d="M352.687 0H373.05600000000004V5.684H352.687z"></path>
        <circle cx="276.896" cy="54.002" r="22.264" fill="#2424C9"></circle>
        <circle cx="338.003" cy="16.104" r="6.632" fill="#FFC501"></circle>
        <mask
          id="mask0_1385_5389"
          style={{ maskType: "alpha" }}
          width="39"
          height="21"
          x="205"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#EC494A" d="M205.843 0H243.265V20.369H205.843z"></path>
        </mask>
        <g mask="url(#mask0_1385_5389)">
          <circle cx="223.843" cy="7.105" r="12.316" fill="#E55CB4"></circle>
        </g>
        <path
          fill="#00DEB5"
          d="M299.16 31.738h53.527v6.158l-.237 1.421-.237.948-.237.947-.237.947-.473 1.185-.711 1.658-.71 1.894-.948 2.132-.71 1.421-.948 1.895-.947 1.658-1.421 1.895-1.658 2.131-1.184 1.421-1.658 1.658-1.421 1.421-1.658 1.421-1.421 1.184-1.658 1.185-1.421.947-1.895 1.184-1.895 1.185-2.131 1.184-1.421.71-1.658.71-1.895.712-2.132.71-3.316.947-2.605.71-3.079.712-2.368.236h-9.237V31.738z"
        ></path>
        <path fill="#2424C9" d="M334.687 0H299.16v31.737L334.687 0z"></path>
        <path fill="#EC494A" d="M299.16 31.737h-44.764V0h13.264l31.5 31.737z"></path>
        <path fill="#00DEB5" d="M211.053 31.738h43.343l-.237 44.527-43.106-44.527z"></path>
        <path fill="#E55CB4" d="M254.633 152.053v-11.842l11.368 11.842h-11.368z"></path>
        <path
          fill="#E55CB4"
          fillRule="evenodd"
          d="M183.576 152.058l.003-.237a8.29 8.29 0 10-16.576.237h16.573z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#00DEB5"
          fillRule="evenodd"
          d="M319.733 152.054a22.184 22.184 0 001.691-8.525c0-12.296-9.968-22.263-22.264-22.263-12.295 0-22.263 9.967-22.263 22.263 0 3.02.601 5.899 1.69 8.525h41.146z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#00DEB5"
          fillRule="evenodd"
          d="M290.968 152.057c.093-1.093.14-2.199.14-3.316a38.659 38.659 0 00-6.759-21.901l-.583.584-.711.71-.71.711-.711.947h-.236a33.486 33.486 0 016.868 20.37c0 .636-.018 1.268-.053 1.895h2.755zm-77.406 0a39.528 39.528 0 01-.14-3.316c0-21.452 17.391-38.843 38.843-38.843 10.721 0 20.428 4.344 27.457 11.368h-7.799l.144.129a33.47 33.47 0 00-17.433-4.865c-18.575 0-33.633 15.057-33.633 33.632 0 .636.018 1.268.053 1.895h-7.492z"
          clipRule="evenodd"
        ></path>
        <path fill="#FFC501" d="M254.159 76.262H299.15999999999997V121.263H254.159z"></path>
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1450 321">
        <path fill="#000" d="M0 0h1450v321H0z" />
        <path
          fill="#2424C9"
          d="M1397 12h43v43h-43zM1241 161h113v46h-113zM1397 98h43v43h-43zM1397 184h43v43h-43zM1397 270h43v43h-43z"
        />
        <path fill="#EC494A" d="M1354 227h43v43h-43z" />
        <path fill="#E55CB4" d="M1306 292.678 1323.678 275l17.677 17.678-17.677 17.678z" />
        <path
          fill="#EC494A"
          d="M1241 207h55v49h-55zM1354 313h43v8h-43zM1354 141h43v43h-43zM1001 69h43v43h-43zM1354 55h43v43h-43zM1354 0h43v12h-43z"
        />
        <circle cx={1194} cy={114} r={47} fill="#2424C9" />
        <circle cx={1323} cy={34} r={14} fill="#FFC501" />
        <mask
          id="a"
          width={79}
          height={43}
          x={1044}
          y={0}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "alpha",
          }}
        >
          <path fill="#EC494A" d="M1044 0h79v43h-79z" />
        </mask>
        <g mask="url(#a)">
          <circle cx={1082} cy={15} r={26} fill="#E55CB4" />
        </g>
        <path
          fill="#00DEB5"
          d="M1241 67h113v13l-.5 3-.5 2-.5 2-.5 2-1 2.5-1.5 3.5-1.5 4-2 4.5-1.5 3-2 4-2 3.5-3 4-3.5 4.5-2.5 3-3.5 3.5-3 3-3.5 3-3 2.5-3.5 2.5-3 2-4 2.5-4 2.5-4.5 2.5-3 1.5-3.5 1.5-4 1.5-4.5 1.5-7 2-5.5 1.5-6.5 1.5-5 .5H1241V67Z"
        />
        <path fill="#2424C9" d="M1316 0h-75v67l75-67Z" />
        <path fill="#EC494A" d="M1241 67h-94.5V0h28l66.5 67Z" />
        <path fill="#00DEB5" d="M1055 67h91.5l-.5 94-91-94Z" />
        <path fill="#E55CB4" d="M1147 321v-25l24 25h-24Z" />
        <path
          fill="#E55CB4"
          fillRule="evenodd"
          d="M996.993 321c.005-.166.007-.333.007-.5 0-9.665-7.835-17.5-17.5-17.5s-17.5 7.835-17.5 17.5c0 .167.002.334.007.5h34.986Z"
          clipRule="evenodd"
        />
        <path
          fill="#00DEB5"
          fillRule="evenodd"
          d="M1284.43 321a46.867 46.867 0 0 0 3.57-18c0-25.957-21.04-47-47-47s-47 21.043-47 47c0 6.376 1.27 12.456 3.57 18h86.86Z"
          clipRule="evenodd"
        />
        <path
          fill="#00DEB5"
          fillRule="evenodd"
          d="M1223.71 321c.19-2.307.29-4.642.29-7 0-17.15-5.26-33.07-14.27-46.233L1208.5 269l-1.5 1.5-1.5 1.5-1.5 2h-.5c9.1 11.934 14.5 26.836 14.5 43 0 1.342-.04 2.676-.11 4h5.82Zm-163.42 0a85.273 85.273 0 0 1-.29-7c0-45.287 36.71-82 82-82 22.63 0 43.13 9.171 57.97 24h-16.47l.3.267C1173.07 249.752 1160.47 246 1147 246c-39.21 0-71 31.788-71 71 0 1.342.04 2.676.11 4h-15.82Z"
          clipRule="evenodd"
        />
        <path fill="#FFC501" d="M1146 161h95v95h-95z" />
        <path
          fill="#fff"
          d="M202.346 208.536c-7.936 0-14.848-1.92-20.736-5.76s-10.496-9.216-13.824-16.128c-3.243-6.997-4.864-15.104-4.864-24.32 0-9.387 1.664-17.536 4.992-24.448 3.413-6.912 8.149-12.245 14.208-16 6.059-3.84 13.141-5.76 21.248-5.76 6.485 0 12.416 1.323 17.792 3.968 5.461 2.56 9.856 6.315 13.184 11.264 3.413 4.949 5.248 10.923 5.504 17.92h-25.856c-.341-3.584-1.451-6.613-3.328-9.088-1.792-2.475-4.352-3.712-7.68-3.712-2.901 0-5.419.981-7.552 2.944s-3.797 4.864-4.992 8.704c-1.109 3.755-1.664 8.491-1.664 14.208 0 5.973.597 10.88 1.792 14.72 1.195 3.755 2.816 6.528 4.864 8.32 2.048 1.792 4.352 2.688 6.912 2.688 2.304 0 4.309-.512 6.016-1.536 1.707-1.109 3.072-2.645 4.096-4.608 1.109-2.048 1.792-4.437 2.048-7.168h25.856c-.683 6.827-2.56 12.8-5.632 17.92-3.072 5.035-7.296 8.96-12.672 11.776-5.376 2.731-11.947 4.096-19.712 4.096Zm78.514 0c-7.936 0-14.72-1.408-20.352-4.224-5.547-2.816-9.813-6.784-12.8-11.904-2.901-5.205-4.352-11.307-4.352-18.304v-56.448h25.216v57.088c0 2.731.469 5.12 1.408 7.168 1.024 1.963 2.475 3.499 4.352 4.608 1.877 1.024 4.053 1.536 6.528 1.536 2.645 0 4.864-.512 6.656-1.536 1.792-1.109 3.2-2.645 4.224-4.608 1.024-2.048 1.536-4.437 1.536-7.168v-57.088h25.216v56.448c0 6.997-1.493 13.099-4.48 18.304-2.901 5.12-7.168 9.088-12.8 11.904-5.547 2.816-12.331 4.224-20.352 4.224ZM327.63 207v-89.344h25.216v68.864h41.728V207H327.63Zm99.506 0v-68.992h-24.704v-20.352h74.624v20.352h-24.704V207h-25.216Zm79.282 0v-89.344h25.216V207h-25.216Zm-19.072-89.344h63.36v20.352h-63.36v-20.352Zm0 68.864h63.36V207h-63.36v-20.48ZM580.324 207l-20.352-89.344h25.216l10.24 47.744 3.328 22.784 3.2-22.784 10.368-47.744h25.088L616.804 207h-36.48Z"
        />
      </svg>
    );
  }
};

export default AboutPageIcon;

import { Suspense, lazy } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import Image from "../components/Image";
import Loading from "../components/Loading";
import WebsiteLinkButton from "../components/WebsiteLinkButton";
import useCompanyProfile from "../hooks/useCompanyProfile";
import usePageReset from "../hooks/usePageReset";

export default function CompanyProfile() {
  const { orgSlug } = useParams();
  const [company, isLoading] = useCompanyProfile(orgSlug as string);
  const pageReset = usePageReset();

  const MarkdownPreview = lazy(() => import("../components/MarkDown"));
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="Company profile portfolio cultiv" />
        <meta name="description" content={`${company && company?.name}'s profile`} />
        <meta name="robots" content="index, follow" />
        <title>Cultiv | {`${company ? company.name : "Company Profile"}`}</title>
      </Helmet>

      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <Loading />
        </div>
      ) : (
        <div className="lg:px-20 px-6 w-full flex flex-col lg:gap-5 lg:pt-14 pt-6">
          <h1 className="md:text-3xl lg:text-4xl text-xl font-bold border-b-2 border-gray-400 lg:pb-5 pb-2">
            {company?.name}
          </h1>
          <div className="flex flex-col items-center lg:flex-row lg:items-start lg:gap-7">
            <Image
              src={company?.profile_picture}
              alt={company?.name}
              width={406}
              height={406}
              className="object-contain"
            />
            <div className="pt-3">
              {company?.md_description && (
                <Suspense fallback={<Loading />}>
                  <div className="flex-1">
                    <MarkdownPreview
                      articleBody={company.md_description}
                      proseUl="prose-ul:px-0 prose-ol:px-0 prose-li:px-0"
                    />
                  </div>
                </Suspense>
              )}
              {company?.link && (
                <div className="flex flex-col items-center lg:items-start justify-center lg:pt-6 pt-3">
                  <WebsiteLinkButton link={company.link} text="Visit their Website" />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

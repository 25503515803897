import useCult from "../hooks/useCult";
import Loading from "./Loading";
import PersonGrid from "./PersonGrid";

export interface Props {
  displayed: string;
}

export default function CultMembers({ displayed }: Props) {
  const routes = {
    principals: "cultiv_principal",
    cultivators: "cultiv_cultivator",
    innovators: "cultiv_innovator",
  };
  const role = routes[displayed as keyof typeof routes];
  const [cultMembers, isLoading] = useCult(role);
  return isLoading ? (
    <div className="min-h-[50vh] sm:min-h-[70vh] flex items-center justify-center">
      <Loading />
    </div>
  ) : (
    <PersonGrid members={cultMembers} displayed={displayed} />
  );
}

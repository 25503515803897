import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { panelArr } from "../../assets/cultivatorsEventPanels";
import structuredData from "../../assets/structuredText";
import CardsGrid from "../../components/CardsGrid";
import MarqueeText from "../../components/MarqueeText";
import usePageReset from "../../hooks/usePageReset";
import Image from "../../components/Image";

const CultivatorsHome = () => {
  const pageReset = usePageReset();
  const renderMarqueeText = (count: number) => {
    return Array.from({ length: count }, (_, index) => <MarqueeText key={index} />);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="keywords"
          content="cultiv cultivators entrepreneur startup members collective company consultation networking mentorship business growth idea network global collaboration events"
        />
        <meta
          name="description"
          content="Cultivators, a community-based series of events to empower individuals around the globe to positively impact the future through cross-border networking, knowledge sharing, and mentorship."
        />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">{structuredData}</script>
        <title>Cultivators</title>
      </Helmet>
      <div className="flex flex-col h-full">
        <section className="w-full flex justify-center items-center gap-8 md:px-8 px-3 md:pt-20 md:pb-14 py-4">
          <div className="flex flex-col md:text-3xl lg:text-4xl text-sm text-center items-center justify-center md:px-10 px-0">
            <p className="pb-2.5 xl:leading-[50px]">
              The Global Discussion Panel - Investing in Commercializing Universities R&D
            </p>
            <p className="font-extralight md:text-2xl text-xs md:pt-7 pt-0">Jan 15, 2024</p>
          </div>
        </section>

        <section className="bg-black text-white flex flex-col items-center justify-center lg:bg-[url(/images/cultivators/worldmap.png)] bg-no-repeat bg-center bg-contain lg:py-32 lg:px-28 xl:px-48">
          <h2 className="md:text-5xl lg:text-6xl text-xl font-medium pt-3 lg:pt-0 lg:pb-14">
            Our Biggest Forum Yet
          </h2>
          <Image
            src="images/cultivators/mobile-world-map.png"
            alt="world map"
            className="py-3 px-3 lg:hidden"
          />
          <div className="md:flex px-6 md:px-10 lg:px-0 md:pb-5 lg:pb-16 pb-3 h-full">
            <p className="md:text-base md:leading-5 font-light lg:tracking-wider xl:tracking-normal lg:text-xl xl:text-4xl text-xs flex-grow lg:leading-9 xl:leading-[55px] leading-5 tracking-wider md:pt-5 pt-1 sm:text-justify md:text-justify lg:text-justify">
              We're going global! This year's Cultivators Forum focused on empowering individuals
              around the globe to positively impact the future through cross-border networking,
              knowledge sharing, and mentorship. Get the latest industry insights, mingle with
              fellow practitioners, and get inspired by our keynote speakers during this exciting
              forum.
            </p>
          </div>
        </section>

        <section className="flex md:py-14 py-5 xl:px-20 px-6 items-center justify-center">
          <CardsGrid cards={panelArr} />
        </section>

        <section className="bg-black text-white flex flex-col items-center justify-center">
          <h2 className="md:text-5xl lg:text-6xl text-base md:py-10 pt-5 pb-2.5 text-center">
            Ready To Attend The Next Forum?
          </h2>
          <p className="md:text-3xl lg:text-4xl text-xs font-extralight">
            Let's meet in the 3rd Panel Discussion
          </p>
          <div className="flex md:gap-10 lg:gap-48 gap-6 md:text-2xl font-semibold text-sm md:pt-14 md:pb-10 py-5">
            <Link
              to={"/cultivators/register"}
              className="bg-black text-white ease-in-out duration-300 md:w-60 w-28 h-12 border-2 hover:bg-cultiv-yellow hover:border-cultiv-yellow hover:text-black flex items-center justify-center"
            >
              Join Us
            </Link>
            <Link
              to={"/cult/cultivators"}
              className="bg-black text-white ease-in-out duration-300 md:w-64 w-44 h-12 border-2 hover:bg-cultiv-yellow hover:border-cultiv-yellow hover:text-black flex items-center justify-center"
            >
              Meet Our Network
            </Link>
          </div>
          <div className="w-full border-y-2 md:mb-10 mb-5 overflow-hidden md:[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)] [mask-image:_linear-gradient(to_right,transparent_0,_black_70px,_black_calc(100%-70px),transparent_100%)]">
            <div className="relative flex overflow-x-hidden overflow-y-hidden">
              <div className="animate-marquee whitespace-nowrap text-[0] leading-[0] flex items-center">
                {renderMarqueeText(15)}
              </div>
              <div className="absolute flex animate-marquee2 whitespace-nowrap text-[0] leading-[0] items-center">
                {renderMarqueeText(15)}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CultivatorsHome;

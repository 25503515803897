import { useEffect, useState } from "react";
import Loading from "./Loading";

export interface Image extends React.ImgHTMLAttributes<HTMLImageElement> {}

export default ({ src, alt, className, width, height, ...props }: Image) => {
  const [imgSrc, setImgSrc] = useState(src);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;
    const img = new Image();
    img.src = src as string;
    img.addEventListener("load", () => {
      if (isMounted) {
        setImgSrc(src);
        setLoaded(true);
      }
    });
    return () => {
      isMounted = false;
      img.removeEventListener("load", () => {
        if (isMounted) {
          setImgSrc(src);
          setLoaded(true);
        }
      });
    };
  }, [src]);

  return loaded ? (
    <img
      {...props}
      alt={alt}
      width={width}
      height={height}
      src={imgSrc}
      loading="lazy"
      className={className}
    />
  ) : (
    <div
      className={`flex items-center justify-center ${className}`}
      style={{ width: width, height: height }}
    >
      <Loading />
    </div>
  );
};

import useRelation from "../hooks/useRelation";
import MemberButton from "./MemberButton";

export default function MembersButtonsGrid() {
  const [membersButtons] = useRelation("collective");
  return (
    <div className="grid grid-cols-3 gap-2 w-full h-full">
      {membersButtons.map((member) => {
        return (
          <MemberButton
            key={member.id}
            imgSrc={member.profile_picture}
            slug={member.name_slug}
            name={member.name}
          />
        );
      })}
    </div>
  );
}

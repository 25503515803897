import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface NavMenuTab {
  id: string;
  menuShouldOpen: boolean;
}

export interface NavMenuSlice {
  menus: NavMenuTab[];
}
const initialState: NavMenuSlice = {
  menus: [
    { id: "cult", menuShouldOpen: false },
    { id: "collective", menuShouldOpen: false },
    { id: "cultivation", menuShouldOpen: false },
    { id: "culture", menuShouldOpen: false },
  ],
};

export const navMenuSlice = createSlice({
  name: "navMenu",
  initialState,
  reducers: {
    showMenu: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      const menuItemIndex = state.menus.findIndex((item) => item.id === payload);
      if (menuItemIndex >= 0) {
        state.menus[menuItemIndex].menuShouldOpen = true;
      }
    },
    hideMenu: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      const menuItemIndex = state.menus.findIndex((item) => item.id === payload);
      if (menuItemIndex >= 0) {
        state.menus[menuItemIndex].menuShouldOpen = false;
      }
    },
    reverseMenu: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      const menuItemIndex = state.menus.findIndex((item) => item.id === payload);
      if (menuItemIndex >= 0) {
        const reversedState = !state.menus[menuItemIndex].menuShouldOpen;
        state.menus[menuItemIndex].menuShouldOpen = reversedState;
      }
    },
  },
});

export const { showMenu, hideMenu, reverseMenu } = navMenuSlice.actions;

export default navMenuSlice.reducer;

export const findMenuStateById = (state: NavMenuSlice, menuId: string) =>
  state.menus.find((menu) => menu.id === menuId)?.menuShouldOpen || false;

import { MapPinIcon } from "@heroicons/react/24/outline";
import { CultivContact } from "../schema";

interface ContactDetailsProps {
  name: CultivContact;
  location: CultivContact;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({ name, location }) => {
  return (
    <div className="flex w-full">
      <MapPinIcon className="h-6 w-6 md:pt-1 self-start" />
      <div className="flex flex-col w-full font-light pl-2">
        <p className="text-sm lg:text-lg">{name.contact}</p>
        <p className="text-xs lg:text-sm lg:leading-6">{location.contact}</p>
      </div>
    </div>
  );
};

export default ContactDetails;

import { Transition } from "@headlessui/react";
import { CalendarIcon, UserIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import useTextTruncation from "../hooks/useTextTruncation";
import Image from "./Image";
import ReadMoreAnime from "./ReadMoreAnime";

export interface Props {
  title: string;
  imgSrc: string;
  imgAlt: string;
  hoverText: string;
  date: string;
  author?: string;
  slug: string;
  type: string;
}

const CultureCard = ({ title, imgAlt, imgSrc, hoverText, date, author, type, slug }: Props) => {
  const [hovered, setHovered] = useState<boolean>(false);

  const navigate = useNavigate();
  const truncatedText = useTextTruncation(hoverText, 150);
  const truncatedDate = date.split(" ").slice(1).join(" ");
  const location = useLocation();

  return (
    <div
      className="flex flex-col items-center w-full h-full justify-center relative border-cultiv-grey border-2 col-span-4 md:col-span-1 xl:col-span-4 min-h-[500px] lg:min-h-[620px] xl:min-h-[540px] 2xl:min-h-[650px] 4xl:min-h-[820px] max-h-max overflow-hidden hover:cursor-pointer"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => navigate(`/culture/${type}/${slug}`, { state: { from: location } })}
    >
      <Image className="aspect-4/3 min-w-full" height={301} width={402} src={imgSrc} alt={imgAlt} />
      <div className="relative w-full flex-1 max-w-full h-full overflow-hidden">
        <Transition
          show={hovered}
          enter="transition-opacity ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="absolute top-0 left-0 z-20 h-full w-full text-white"
        >
          <div className="flex flex-col h-full cursor-pointer pt-6 bg-cultiv-blue overflow-hidden">
            <div className="flex justify-between text-xs 2xl:text-sm text-white px-6">
              <div className="flex w-20 2xl:w-24">
                <CalendarIcon className="pr-1 w-6" />
                <p className="whitespace-nowrap self-center">{truncatedDate}</p>
              </div>
              {author && (
                <div className="flex">
                  <UserIcon className="text-cultiv-grey w-6" />
                  <p className="self-center">{author}</p>
                </div>
              )}
            </div>
            <p className="font-light px-6 pt-6 text-sm md:text-base lg:text-base lg:!leading-7 2xl:text-lg">
              {truncatedText}
            </p>
            <div className="flex flex-1 justify-end items-end pb-2 2xl:pb-2 pr-6 2xl:pr-6">
              <ReadMoreAnime color="white" />
            </div>
          </div>
        </Transition>
        <div className="min-w-full h-full flex flex-col pt-6 cursor-pointer overflow-hidden">
          <div className="flex justify-between text-xs 2xl:text-sm font-extralight md:font-normal text-[#302D2D] px-6">
            <div className="flex w-20 2xl:w-24">
              <CalendarIcon className="text-cultiv-grey pr-1 w-6" />
              <p className="whitespace-nowrap self-center">{truncatedDate}</p>
            </div>
            {author && (
              <div className="flex">
                <UserIcon className="text-cultiv-grey w-6" />
                <p className="self-center">{author}</p>
              </div>
            )}
          </div>
          <h3 className="font-semibold text-lg lg:text-[22px] 3xl:text-2xl pt-6 px-6 break-words overflow-hidden">
            {title}
          </h3>
          <div className="flex-1 flex justify-end">
            <h4
              className={`bg-cultiv-red text-black font-medium flex justify-center items-center self-end text-sm ${
                type.length <= 7 ? "w-24" : "w-32"
              } py-[3px] uppercase`}
            >
              {type}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CultureCard;

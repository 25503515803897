import { useEffect, useState } from "react";
import { rssFeedItems, podcastData } from "../assets/rssFeedItems";
import { create } from "xmlbuilder2";
import PodcastPlatformLink from "../components/PodcastPlatformLink";
import Image from "../components/Image";

const PodcastRSSFeed = () => {
  const [rssFeed, setRssFeed] = useState<string>("");
  const rssFeedGenerator = () => {
    let rss = `<?xml version="1.0" encoding="UTF-8"?>\n`;
    rss += `<rss xmlns:podcast="https://podcastindex.org/namespace/1.0" xmlns:content="http://purl.org/rss/1.0/modules/content/" xmlns:wfw="http://wellformedweb.org/CommentAPI/" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:atom="http://www.w3.org/2005/Atom" xmlns:sy="http://purl.org/rss/1.0/modules/syndication/" xmlns:slash="http://purl.org/rss/1.0/modules/slash/" xmlns:itunes="http://www.itunes.com/dtds/podcast-1.0.dtd" xmlns:rawvoice="http://www.rawvoice.com/rawvoiceRssModule/" xmlns:googleplay="http://www.google.com/schemas/play-podcasts/1.0/play-podcasts.xsd" version="2.0">\n`;
    rss += `<channel>\n`;
    rss += `<title>${podcastData.title}</title>\n`;
    rss += `<atom:link href="https://cultiv.llc/culture/podcast/feed" rel="self"  type="application/rss+xml"/>\n`;
    rss += `<link>https://cultiv.llc/culture/podcast/${podcastData.title}</link>\n`;
    rss += `<description>${podcastData.description}</description>\n`;
    rss += `<image><url>${podcastData.image.url}</url>\n<title>${podcastData.image.title}</title>\n<link>${podcastData.image.link}</link>\n<width>${podcastData.image.width}</width>\n<height>${podcastData.image.height}</height></image>\n`;
    rss += `<itunes:summary>${podcastData.itunes.summary}</itunes:summary>\n`;
    rss += `<itunes:explicit>${podcastData.itunes.explicit}</itunes:explicit>\n`;
    rss += `<itunes:image href="${podcastData.itunes.image.href}" />\n`;
    rss += `<itunes:owner>\n`;
    rss += `<itunes:name>${podcastData.itunes.owner.name}</itunes:name>\n`;
    rss += `<itunes:email>${podcastData.itunes.owner.email}</itunes:email>\n`;
    rss += `</itunes:owner>\n`;
    rss += `<copyright>${podcastData.copyright}</copyright>\n`;
    rss += `<itunes:subtitle>${podcastData.itunes.subtitle}</itunes:subtitle>\n`;
    rss += `<image>\n`;
    rss += `<title>${podcastData.image}</title>\n`;
    rss += `<url>${podcastData.itunes.image.href}</url>\n`;
    rss += `<link>${podcastData.image.link}</link>\n`;
    rss += `</image>\n`;
    rss += `<itunes:category text="${podcastData.itunes.category[0].text}">\n`;
    rss += `<itunes:category text="${podcastData.itunes.category[1].text}"/>\n`;
    rss += `<itunes:category/>\n`;
    rss += `<rawvoice:rating>${podcastData.rawvoice.rating}</rawvoice:rating>\n`;
    rss += `<rawvoice:location>${podcastData.rawvoice.location}</rawvoice:location>\n`;
    rss += `<rawvoice:frequency>${podcastData.rawvoice.frequency}</rawvoice:frequency>\n`;
    rssFeedItems.forEach(
      ({
        categories,
        creator,
        description,
        guid,
        link,
        title,
        author,
        content,
        duration,
        enclosure,
        explicit,
        pubDate,
        subtitle,
        summary,
      }) => {
        rss += `<item>\n`;
        rss += `<title>${title}</title>\n`;
        rss += `<link>${link}</link>\n`;
        rss += `<pubdate>${pubDate}</pubdate>\n`;
        rss += `<dc:creator><![CDATA[${creator}]]></dc:creator>\n`;
        categories.forEach((category) => {
          rss += `<category><![CDATA[${category}]]></category>\n`;
        });
        rss += `<guid isPermaLink = "false">${guid}</guid>\n`;
        rss += `<description><![CDATA[${description}]]></description>\n`;
        rss += `<content:encoded><![CDATA[<p>${content}</p>]]></content:encoded>\n`;
        rss += `<enclosure url="${enclosure?.url}" length="${enclosure?.length}" type="${enclosure?.type}" />\n`;
        rss += `<itunes:subtitle>${subtitle}</itunes:subtitle>\n`;
        rss += `<itunes:summary>${summary}</itunes:summary>\n`;
        rss += `<itunes:author>${author}</itunes:author>\n`;
        rss += `<itunes:explicit>${explicit}</itunes:explicit>\n`;
        rss += `<itunes:duration>${duration}</itunes:duration>\n`;
        rss += `</item>\n`;
      }
    );
    rss += `</channel>\n`;
    rss += `</rss>\n`;
    setRssFeed(rss);
  };

  const handleClick = async () => {
    rssFeedGenerator();
    const xml = create(rssFeed).end({ prettyPrint: true });
    const blob = new Blob([xml], { type: "application/xml" });
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");
  };
  return (
    <button
      className="size-11 lg:size-13 col-span-1 flex items-center justify-center rounded-full"
      onClick={handleClick}
    >
      <Image
        src={"/images/RSS.png"}
        width={50}
        height={50}
        className="grayscale hover:grayscale-0 rounded-full "
      />
    </button>
  );
};

export default PodcastRSSFeed;

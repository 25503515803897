const structuredData = JSON.stringify({
  "@context": "http://schema.org",
  "@type": "Organization",
  name: "Cultiv LLC",
  logo: "https://people.bb.cultiv.llc//storage/6/cultiv.png",
  description:
    "Cultiv represents a group of interdependent companies providing a diverse set of activities all within the field of innovation",
  url: "https://cultiv.llc",
  sameAs: [
    "https://www.facebook.com/cultivllc",
    "https://twitter.com/CultivLLC",
    "https://www.instagram.com/cultiv.llc/",
    "https://www.linkedin.com/company/cultivllc",
  ],
});

export default structuredData;

import RightChevron from "./Icons/RightChevron";
import { Transition } from "@headlessui/react";

export interface Props {
  color?: string;
}

const ReadMoreAnime = ({ color }: Props) => {
  return (
    <div className="flex items-center flex-1 justify-end">
      <Transition.Child
        enter="transition-transform ease-in duration-[450ms]"
        enterFrom="translate-x-0"
        enterTo="translate-x-24 2xl:translate-x-32"
        leave="transition-transform ease-out duration-0"
        leaveFrom="translate-x-24"
        leaveTo="translate-x-0"
        className="2xl:pr-5"
      >
        <RightChevron color={`${color}`} />
      </Transition.Child>
      <Transition.Child
        enter="transition-opacity ease-in duration-[800ms]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-out duration-0"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={`pr-5 text-sm text-${color} 2xl:text-base`}
      >
        Read More
      </Transition.Child>
    </div>
  );
};

export default ReadMoreAnime;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { cultivatorsEventPanels } from "../assets/cultivatorsEventPanels";
import { Post } from "../schema";

const useEventSeries = (eventSeriesID: string) => {
  const [series, setSeries] = useState<Post>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    let cancelRequest = false;
    const seriesData = cultivatorsEventPanels.find((panel) => panel.title_slug === eventSeriesID);

    if (!seriesData) {
      navigate(`/404`);
      return;
    }

    setSeries(seriesData);
    setIsLoading(false);
    return () => {
      cancelRequest = true;
    };
  }, [eventSeriesID]);

  return { series, isLoading };
};

export default useEventSeries;

export const cultDetails = {
  text: "By being associated with us, you will be part of a cult, in the most positive sense. We are a group of change makers, thought leaders and experimenters. We take pride in being different, and not afraid to be the minority, because people who make an impact on the world often are.",
};

export const collectiveDetails = {
  text: "Our organization is not a single entity, but a group of diverse business models and directions. We have a common vision and some shared resources, but take independent approaches.",
};

export const cultivationDetails = {
  text: "Our aim is to cultivate ideas, initiatives and products. We want to cultivate the future of people, individually as leaders, innovators, and career builders, as well as in groups in the form of companies, organizations, societies, and movement.",
};

export const cultureDetails = {
  text: "We are all about culture, in the way we work as a team, and in what we offer to the world. We engage with the existing culture, and depend on it as a source of innovation and design. We influence culture within our organization and work environment, and in the communities we work to encourage innovation, progress and responsibility.",
};

export const cultivatorsDetails = {
  text: " The Cultivators is a community-based initiative focused on empowering individuals to positively impact the future. Its goal is to support mentors, market experts, entrepreneurs, and investors in achieving their business objectives through cross-border networking opportunities and knowledge sharing throughout the MENA region. The Cultivators aims to nurture and engage these professionals.",
};

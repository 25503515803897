import { Departments } from "../schema";
import JoinCultivators from "./JoinCultivators";
import JoinTheTeam from "./JoinTheTeam";

export interface Props {
  displayed: string;
}

const JoinSelector = ({ displayed }: Props) => {
  return displayed === Departments.cultivators ? <JoinCultivators /> : <JoinTheTeam />;
};

export default JoinSelector;

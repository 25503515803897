import useRelation from "../hooks/useRelation";
import CompanyGrid from "./CompanyGrid";
import Loading from "./Loading";

export interface Props {
  relationType: string;
}

export default function CompaniesFeed({ relationType }: Props) {
  const [companies, isLoading] = useRelation(relationType);

  return isLoading ? (
    <div className="min-h-[50vh] sm:min-h-[70vh] flex items-center justify-center">
      <Loading />
    </div>
  ) : (
    <CompanyGrid cards={companies} />
  );
}

import { Company } from "../schema";
import CompanyPreviewCard from "./CompanyPreviewCard";
export interface Props {
  cards: Company[];
}

export default function CompanyGrid({ cards }: Props) {
  return (
    <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-5 gap-6 w-full place-items-start">
      {cards.map((card) => {
        return <CompanyPreviewCard key={card.id} company={card} />;
      })}
    </div>
  );
}

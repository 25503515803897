import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Company } from "../schema";

const useRelation = (
  relation: string,
  page?: number,
  limit?: number
): [Company[], boolean, number] => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastPage, setLastPage] = useState<number>(0);
  const navigate = useNavigate();
  useEffect(() => {
    let cancelRequest = false;
    const fetchCompaniesData = async () => {
      try {
        const response = await fetch(
          import.meta.env.VITE_GET_COMPANY_FEED +
            relation +
            `?is_short&page=${page}&${limit && `limit=${limit}`}`
        );
        if (!response.ok) {
          throw new Error(response.status + response.statusText + "Failed to fetch data");
        }
        if (!cancelRequest) {
          const { data, meta } = await response.json();
          setCompanies(data);
          setLastPage(meta?.last_page);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        navigate(`/404`);
      }
    };
    fetchCompaniesData();
    return () => {
      cancelRequest = true;
    };
  }, [relation, page, limit]);
  return [companies, isLoading, lastPage];
};

export default useRelation;

import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { contacts, footerLinks } from "../assets/footer";
import ContactDetails from "./ContactDetails";
import FooterLink from "./FooterLink";
import Facebook from "./Icons/Facebook";
import Instagram from "./Icons/Instagram";
import Linkedin from "./Icons/Linkedin";

export default function Footer() {
  return (
    <footer className="bg-black">
      <div className="grid gap-y-6 xl:justify-between lg:gap-x-7.5 grid-rows-2 lg:grid-rows-1 grid-cols-12 lg:py-20 py-8 text-gray-50 lg:px-20 px-6">
        <div className="flex flex-col col-span-5 lg:col-span-3 justify-evenly gap-3 lg:gap-10">
          <h2 className="text-lg sm:text-xl md:text-2xl xl:text-3xl font-bold uppercase">
            Quick links
          </h2>
          <div className="flex flex-col justify-between h-full font-normal">
            {footerLinks.map((link, index) => {
              return <FooterLink key={index} title={link.title} pathname={link.pathname} />;
            })}
          </div>
        </div>
        <div className="flex flex-col col-span-12 lg:col-span-5 lg:col-start-5 order-3 lg:order-2 items-start gap-3 lg:gap-10">
          <h2 className="text-lg sm:text-xl md:text-2xl xl:text-3xl font-bold uppercase">
            Find Us
          </h2>
          <div className="flex flex-col justify-between gap-4">
            {contacts.map(([name, location], index) => {
              return <ContactDetails key={index} name={name} location={location} />;
            })}
          </div>
        </div>
        <div className="flex flex-col col-span-4 lg:col-span-3 col-end-13 order-2 lg:order-3 justify-between items-center">
          <div>
            <Link to={`/`}>
              <img
                src="/images/Cultiv-footer.svg"
                className="pb-5 lg:pb-0 w-20 md:w-[139px]"
                alt="Cultiv square logo"
              />
            </Link>
          </div>
          <div>
            <a href="mailto:info@cultiv.llc">
              <div className="flex gap-1 lg:gap-2.5 items-center justify-center pb-4 lg:pb-7">
                <EnvelopeIcon className="w-4 h-4 lg:w-6 lg:h-6"></EnvelopeIcon>
                <p className="text-xs md:text-sm lg:text-xl">info@cultiv.llc</p>
              </div>
            </a>
            <div className="grid grid-cols-2 grid-rows-2 lg:flex gap-7.5 justify-between">
              <a
                href="https://www.linkedin.com/company/cultivllc"
                target="_blank"
                aria-label="Go to cultiv's Linkedin page"
              >
                <Linkedin color="white" hoverColor="cultiv-yellow" />
              </a>
              <a
                href="https://www.facebook.com/cultivllc"
                target="_blank"
                aria-label="Go to cultiv's Facebook page"
              >
                <Facebook color="white" hoverColor="cultiv-yellow" />
              </a>
              {/* <a
                href="https://twitter.com/CultivLLC"
                target="_blank"
                aria-label="Go to cultiv's X account"
              >
                <Twitter color="white" hoverColor="cultiv-yellow" />
              </a> */}
              <a
                href="https://www.instagram.com/cultiv.llc/"
                target="_blank"
                aria-label="Go to cultiv's Instagram account"
              >
                <Instagram color="white" hoverColor="cultiv-yellow" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

import { createBrowserRouter } from "react-router-dom";
import CultivatorsLayout from "./components/Layouts/CultivatorsLayout";
import DefaultLayout from "./components/Layouts/DefaultLayout";
import About from "./pages/About";
import Article from "./pages/Article";
import Bureau from "./pages/Bureau/Bureau";
import Careers from "./pages/Careers";
import Collective from "./pages/Collective";
import CompanyProfile from "./pages/CompanyProfile";
import Cult from "./pages/Cult";
import Cultivation from "./pages/Cultivation";
import CultivatorsHome from "./pages/Cultivators/CultivatorsHome";
import Register from "./pages/Cultivators/Register";
import Culture from "./pages/Culture";
import ErrorPage from "./pages/ErrorPage";
import Event from "./pages/Event";
import EventSeries from "./pages/EventSeries";
import Home from "./pages/Home";
import PersonProfile from "./pages/PersonProfile";
import PodcastEpisode from "./pages/PodcastEpisode";
import PodcastSeries from "./pages/PodcastSeries";
import ProgramPage from "./pages/ProgramPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/careers",
        element: <Careers />,
      },
      {
        path: "/join",
        element: <Cult />,
      },
    ],
  },
  {
    path: "/cult",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/cult",
        element: <Cult />,
      },
      {
        path: "/cult/:department",
        element: <Cult />,
      },
    ],
  },
  {
    path: "/collective",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/collective",
        element: <Collective />,
      },
      {
        path: "/collective/:companyType",
        element: <Collective />,
      },
    ],
  },
  {
    path: "/cultivation",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/cultivation",
        element: <Cultivation />,
      },
      {
        path: "/cultivation/:cultivationTab",
        element: <Cultivation />,
      },
      {
        path: "/cultivation/program/:programID",
        element: <ProgramPage />,
      },
    ],
  },
  {
    path: "/culture",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/culture",
        element: <Culture />,
      },
      {
        path: "/culture/:cultureTab",
        element: <Culture />,
      },
      {
        path: "/culture/article/:articleID",
        element: <Article />,
      },
      {
        path: "/culture/event/:eventID",
        element: <Event />,
      },
      {
        path: "/culture/event-series/:eventSeriesID",
        element: <EventSeries />,
      },
      {
        path: "/culture/event-series/:eventSeriesID/:panels",
        element: <EventSeries />,
      },
      {
        path: "/culture/event/:eventID/gallery",
        element: <Event />,
      },
      {
        path: "/culture/news/:articleID",
        element: <Article />,
      },
      {
        path: "/culture/podcast/:podcastSeriesID",
        element: <PodcastSeries />,
      },
      {
        path: "/culture/podcast/episode/:podcastEpisodeID",
        element: <PodcastEpisode />,
      },
    ],
  },
  {
    path: "/organizations/:orgSlug",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/organizations/:orgSlug",
        element: <CompanyProfile />,
      },
    ],
  },
  {
    path: "/profile/:personID",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/profile/:personID",
        element: <PersonProfile />,
      },
    ],
  },
  {
    path: "/bureau",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Bureau />,
      },
    ],
  },
  {
    path: "/cultivators",
    element: <CultivatorsLayout />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/cultivators", element: <CultivatorsHome /> },
      { path: "/cultivators/register", element: <Register /> },
    ],
  },
  {
    path: "/404",
    element: <ErrorPage />,
  },
]);

export default router;

import useProgramsFeed from "../hooks/useProgramsFeed";
import Loading from "./Loading";
import ProgramCard from "./ProgramCard";

export default function ProgramFeed() {
  const [programs, isLoading] = useProgramsFeed();

  return isLoading ? (
    <div className="min-h-[50vh] sm:min-h-[70vh] flex items-center justify-center">
      <Loading />
    </div>
  ) : (
    <div
      id="programs"
      className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 w-full place-items-start"
    >
      {programs?.map((program) => {
        return (
          <ProgramCard
            key={program.id}
            imgSrc={program.program_thumbnail}
            title={program.title}
            desc={program.program_summary}
            slug={program.title_slug}
            cycles={program.cycles_count}
          />
        );
      })}
    </div>
  );
}

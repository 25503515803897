import { useEffect, useState } from "react";
import { PodcastSeries } from "../schema";
import { useNavigate } from "react-router";

const usePodcastSeries = (): [PodcastSeries[], boolean] => {
  const [series, setSeries] = useState<PodcastSeries[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    let cancelRequest = false;
    const getPodcastSeries = async () => {
      try {
        const response = await fetch("https://posts.bb.cultiv.llc/api/temp-podcast?path=podcast", {
          method: "GET",
        });
        if (!response.ok) {
          throw new Error(
            `An error has occurred while fetching the podcast series Error Status: ${response.status}`
          );
        }
        if (!cancelRequest) {
          const data = await response.json();
          const radioElAfkar: PodcastSeries[] = [
            {
              id: 50,
              title: data[0].basename,
              slug: "radio-elafkar",
              description:
                "Radio-elafkar is a podcast that hosts creators, innovators, artists, scientists, entrepreneurs and visionaries of all kinds.",
              type: "podcast",
              view_count: 0,
              is_featured: 0,
              created_at: "09 May 2024",
              image: "/images/radioaf.jpg",
              numberOfEpisodes: "44",
              duration: "20:53:00",
              audioSummary:
                "https://posts.bb.cultiv.llc/storage/podcast/radio-elafkar/001-intro.mp3",
              post_meta: {
                author: "Hesham Wahby",
                start_date: "2023-11-13T20:00:00.000000Z",
              },
              tags: [
                {
                  id: 1,
                  tag: "podcast",
                },
              ],
            },
          ];
          setSeries(radioElAfkar);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        navigate(`/404`);
      }
    };
    getPodcastSeries();
    return () => {
      cancelRequest = true;
    };
  }, []);

  return [series, isLoading];
};

export default usePodcastSeries;

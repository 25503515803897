import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useSlider from "../hooks/useSlider";
import { SliderItem } from "../schema";
import SliderPanel from "./SliderPanel";

export default function Slider() {
  const [selected, setSelected] = useState<number>(0);
  const [imgArr, isLoading] = useSlider(8);
  const [isInside, setIsInside] = useState<boolean>(false);
  const sliderItems: SliderItem[] = [
    // {
    //   post_cover: "/images/Cultivators.png",
    //   title: "Apply now to become a Cultivator",
    //   title_slug: "cultivators",
    //   type: "advertisement",
    //   isDirectory: true,
    // },
    // {
    //   post_cover: "/images/C-Bureau.png",
    //   title: "Cultivators Forum Lisbon 2023",
    //   title_slug: "bureau",
    //   type: "advertisement",
    //   isDirectory: true,
    // },
    {
      post_cover: "/images/cultivators/cultivators-slide.webp",
      title: "Cultivators Forum Lisbon 2023",
      title_slug: "culture/event-series/cultivators-forum",
      type: "event",
      isDirectory: true,
    },
    ...imgArr,
  ];
  return (
    <div
      className="col-span-1 md:col-span-2 flex flex-col justify-start aspect-video"
      onMouseEnter={() => {
        setIsInside(true);
      }}
      onMouseLeave={() => {
        setIsInside(false);
      }}
    >
      {!isLoading && (
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          stopOnHover={true}
          selectedItem={selected}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          onChange={(index) => {
            setSelected(index);
          }}
        >
          {sliderItems.map((img, index) => {
            return (
              <SliderPanel
                imgSrc={img.post_cover}
                type={img.type}
                slug={img.title_slug}
                key={index}
                title={img.title}
                isInside={isInside}
                isDirectory={img.isDirectory}
              />
            );
          })}
        </Carousel>
      )}
    </div>
  );
}

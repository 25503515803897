import { Outlet } from "react-router-dom";
import usePathname from "../../hooks/usePathname";
import Footer from "../Footer";
import Navbar from "../Navbar";

const CultivatorsLayout = () => {
  const { subDirectory } = usePathname();
  return (
    <main className="min-h-screen w-screen flex flex-col justify-between relative overflow-x-hidden">
      <Navbar />
      <div
        className={`w-full bg-black flex justify-center py-5 md:py-14 md:px-8 px-16 ${
          subDirectory === "register" && "hidden"
        }  `}
      >
        <img src="/images/cultivators/cultivators-cover.svg" alt="cultivators logo" />
      </div>
      <div className="flex-1">
        <Outlet />
      </div>
      <Footer />
    </main>
  );
};

export default CultivatorsLayout;

import { PodcastPlatform } from "../schema";
import Image from "./Image";

const PodcastPlatformLink = ({ src, url }: PodcastPlatform) => {
  return (
    <a
      className="size-11 lg:size-13 col-span-1 flex items-center justify-center rounded-full"
      href={url}
      target="_blank"
    >
      <Image
        src={src}
        width={50}
        height={50}
        className="grayscale hover:grayscale-0 rounded-full "
      />
    </a>
  );
};

export default PodcastPlatformLink;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MemberModalSlice {
  open: boolean;
  name: string;
}

const initialState: MemberModalSlice = {
  open: false,
  name: "",
};

export const memberModalSlice = createSlice({
  name: "MemberModal",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<string>) => {
      document.querySelector("html")?.classList.add("overlay");
      const { payload } = action;
      state.open = true;
      state.name = payload;
    },
    closeModal: (state) => {
      document.querySelector("html")?.classList.remove("overlay");
      state.open = false;
    },
  },
});

export const { openModal, closeModal } = memberModalSlice.actions;
export default memberModalSlice.reducer;

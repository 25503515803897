import { createSlice } from "@reduxjs/toolkit";

interface Action {
  payload: string;
  type: string;
}

interface NextAction {
  payload: number;
  type: string;
}

export interface CultivatorsImageModalSlice {
  openImageModal: boolean;
  imgSrc: string;
}

const initialState: CultivatorsImageModalSlice = {
  imgSrc: "",
  openImageModal: false,
};

export const cultivatorsImageModalSlice = createSlice({
  name: "CultivatorsImageModal",
  initialState,
  reducers: {
    open: (state, { payload }: Action) => {
      document.querySelector("html")?.classList.add("overlay");
      state.imgSrc = payload;
      state.openImageModal = true;
    },
    close: (state) => {
      document.querySelector("html")?.classList.remove("overlay");
      state.openImageModal = false;
    },
    next: (state, { payload }: NextAction) => {
      const upperLimit = payload - 1;
      if (Number(state.imgSrc) < upperLimit) {
        const newImgSrc = Number(state.imgSrc) + 1;
        state.imgSrc = newImgSrc.toString();
      }
    },
    prev: (state) => {
      if (Number(state.imgSrc) > 0) {
        const newImgSrc = Number(state.imgSrc) - 1;
        state.imgSrc = newImgSrc.toString();
      }
    },
  },
});

export const { open, close, next, prev } = cultivatorsImageModalSlice.actions;
export default cultivatorsImageModalSlice.reducer;

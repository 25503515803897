import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ProgramCard } from "../schema";

const useProgramsFeed = (): [ProgramCard[], boolean] => {
  const [programs, setPrograms] = useState<ProgramCard[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  useEffect(() => {
    let cancelRequest = false;
    const getPrograms = async () => {
      try {
        const response = await fetch(import.meta.env.VITE_GET_FEED_PROGRAM + "?is_short", {
          method: "GET",
        });
        if (!response.ok) {
          throw new Error(
            response.status +
              response.statusText +
              "An error has ocurred while fetching the programs"
          );
        }
        if (!cancelRequest) {
          const { data } = await response.json();
          setPrograms(data);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        navigate("/404");
      }
    };
    getPrograms();
    return () => {
      cancelRequest = true;
    };
  }, []);
  return [programs, isLoading];
};

export default useProgramsFeed;

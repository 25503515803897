import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { PodcastEpisode, PodcastEpisodeMeta } from "../schema";
import { rssFeedItems } from "../assets/rssFeedItems";

type usePodcastEpisodeOutput = {
  isLoading: boolean;
  episode: PodcastEpisode;
  currentEpisode: PodcastEpisodeMeta[];
};

const usePodcastEpisode = (episodeBasename: string): usePodcastEpisodeOutput => {
  const [episode, setEpisode] = useState<PodcastEpisode>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentEpisode, setCurrentEpisode] = useState<PodcastEpisodeMeta[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    let cancelRequest = false;
    const fetchEpisodes = async (episodeID: string) => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `https://posts.bb.cultiv.llc/api/temp-podcast?path=podcast/radio-elafkar`
        );
        if (!response.ok) {
          throw new Error("An Error has occurred while fetching series episodes");
        }
        if (!cancelRequest) {
          const data = await response.json();
          const episodeFound = data.filter(
            (episode: PodcastEpisode) => episode.filename === episodeID
          );
          setEpisode(episodeFound[0]);
          setIsLoading(false);
          const episode = rssFeedItems.filter(({ link }) =>
            link.includes(episodeFound[0].filename.replace(/[() \d+]/g, ""))
          );
          setCurrentEpisode(episode);
        }
      } catch (error) {
        console.log(error, "this series doesn't exist or has been deleted");
        navigate(`/404`);
      }
    };
    fetchEpisodes(episodeBasename);
    return () => {
      cancelRequest = true;
    };
  }, [episodeBasename]);

  return {
    isLoading: isLoading,
    episode: episode as PodcastEpisode,
    currentEpisode: currentEpisode,
  };
};

export default usePodcastEpisode;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useHomeFeed from "../hooks/useHomeFeed";
import { nextPage, previousPage, reset, setMaxPage } from "../redux/features/pagePaginationSlice";
import { contentTypes } from "../schema";
import CardsGrid from "./CardsGrid";
import RightChevron from "./Icons/RightChevron";
import Loading from "./Loading";

export interface Props {
  page: number;
  pageId: string;
}

const HomeFilter = ({ page, pageId }: Props) => {
  const [displayed, setDisplayed] = useState<string>("");
  const [posts, isLoading, lastPage] = useHomeFeed(page, displayed);

  const dispatch = useDispatch();

  const next = () => {
    dispatch(nextPage(pageId));
  };

  const previous = () => {
    dispatch(previousPage(pageId));
  };

  const resetPage = () => {
    dispatch(reset(pageId));
  };

  const cleanPage = () => {
    dispatch(reset("home"));
  };

  useEffect(() => {
    dispatch(setMaxPage({ id: "home", maxPage: lastPage }));
    return () => cleanPage();
  }, [lastPage]);

  const leftArrowColor = page < 2 ? "#CCCCCCCC" : "black";
  const rightArrowColor = page === lastPage ? "#CCCCCCCC" : "black";

  return (
    <>
      <div className="border-b-2 border-cultiv-grey w-full grid lg:grid-cols-12 lg:pt-10 lg:pb-5 pb-2">
        <div className="col-span-6 lg:text-2xl text-sm flex justify-between">
          {Object.keys(contentTypes).map((type) => (
            <button
              className={`${
                displayed === contentTypes[type as keyof typeof contentTypes]
                  ? "font-medium"
                  : "text-cultiv-grey"
              } transition duration-500 hover:text-black`}
              key={type}
              onClick={() => {
                setDisplayed(contentTypes[type as keyof typeof contentTypes]);
                resetPage();
              }}
            >
              <h3>{type}</h3>
            </button>
          ))}
        </div>

        <div className={`${lastPage === 1 && "hidden"} flex col-end-13 justify-end items-center`}>
          <button
            onClick={previous}
            disabled={page < 2}
            className={page < 2 ? "cursor-not-allowed opacity-50" : undefined}
          >
            <RightChevron color={leftArrowColor} className={`lg:w-8 w-4 rotate-180`} />
          </button>
          <div className="lg:px-6 px-3 lg:text-2xl text-sm">{page}</div>
          <button
            onClick={next}
            disabled={page == lastPage}
            className={page === lastPage ? "cursor-not-allowed opacity-50" : undefined}
          >
            <RightChevron color={rightArrowColor} className={`lg:w-8 w-4`} />
          </button>
        </div>
      </div>

      {isLoading ? <Loading /> : <CardsGrid cards={posts} />}
    </>
  );
};

export default HomeFilter;

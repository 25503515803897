import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import usePathname from "../hooks/usePathname";
import { close } from "../redux/features/mobileDrawerSlice";
import { hideMenu, reverseMenu, showMenu } from "../redux/features/navMenuSlice";
import { Navtab } from "../schema";
import NavDropDownMenu from "./NavDropDownMenu";

export interface Props {
  tab: Navtab;
}

const NavMenu = ({ tab }: Props) => {
  const { page } = usePathname();
  const dispatch = useDispatch();
  const openMenu = () => {
    dispatch(showMenu(tab.id));
  };
  const closeMenu = () => {
    dispatch(hideMenu(tab.id));
  };
  const menuReverse = () => {
    dispatch(reverseMenu(tab.id));
  };
  const closeDrawer = () => {
    dispatch(close());
  };
  return (
    <div
      className={`flex flex-col items-start md:flex-row md:gap-0 gap-1 md:items-center transition-colors  hover:duration-100 duration-300 transform md:my-0 w-full relative`}
      onMouseEnter={isMobile ? () => false : openMenu}
      onMouseLeave={closeMenu}
    >
      <Link
        className="z-20 p-1 w-full"
        onClick={() => {
          menuReverse();
          isMobile && closeDrawer();
        }}
        to={tab.to}
      >
        <h1
          className={`uppercase font-bold text-lg md:text-xl xl:text-2xl hover:text-cultiv-yellow ${
            page === tab.id
              ? "text-cultiv-yellow z-10 underline decoration-cultiv-yellow underline-offset-8"
              : "text-gray-200"
          }`}
        >
          {tab.name}
        </h1>
      </Link>
      {tab.sublinks && (
        <NavDropDownMenu to={tab.to} pathname={page} id={tab.id} dropdownItems={tab.sublinks} />
      )}
    </div>
  );
};
export default NavMenu;

import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router";
import { store } from "./redux/configureStore";
import router from "./router";
import "react-responsive-modal/styles.css";
import "./index.css";

function App() {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    </Provider>
  );
}

export default App;

export interface Props {
  desc?: string;
  color?: string;
  textColor?: string;
}

export default function Header({ color, desc, textColor = "black" }: Props) {
  return (
    <div
      id="header"
      className={`${
        color && color
      } relative w-full flex md:gap-4 py-5 justify-start items-center lg:px-20 px-6`}
    >
      <p className={`lg:text-2xl md:text-base text-sm text-${textColor} font-light text-start`}>
        {desc}
      </p>
    </div>
  );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DrawerController {
  drawerShouldOpen: boolean;
}

const initialState: DrawerController = {
  drawerShouldOpen: false,
};

export const drawerControllerSlice = createSlice({
  name: "drawerController",
  initialState,
  reducers: {
    reverse: (state) => {
      state.drawerShouldOpen = !state.drawerShouldOpen;
    },
    close: (state) => {
      state.drawerShouldOpen = false;
    },
  },
});

export const { reverse, close } = drawerControllerSlice.actions;
export default drawerControllerSlice.reducer;

import { useEffect, useState } from "react";
import { panelArr } from "../assets/cultivatorsEventPanels";
import { Panels, Post } from "../schema";
import { useNavigate } from "react-router";

const useEvent = (eventID: string): [Post, boolean] => {
  const [eventData, setEventData] = useState<Post>({} as Post);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    let cancelRequest = false;
    if (!eventID.includes("panel")) {
      const fetchEventData = async () => {
        try {
          const response = await fetch(
            import.meta.env.VITE_GET_ARTICLE + eventID + "?increase_view_count=1",
            {
              method: "GET",
            }
          );
          if (!response.ok)
            throw new Error(
              `error code: ${response.status}, The event you are looking for is not found `
            );
          if (!cancelRequest) {
            const { data } = await response.json();
            setEventData(data);
            setIsLoading(false);
          }
        } catch (error) {
          console.log(error);
          navigate(`/404`);
        }
      };
      fetchEventData();
    } else {
      const data = panelArr[Panels[eventID as keyof typeof Panels]];
      setEventData(data);
      setIsLoading(false);
    }
    return () => {
      cancelRequest = true;
    };
  }, [eventID]);

  return [eventData, isLoading];
};

export default useEvent;

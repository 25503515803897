import { useEffect, useState } from "react";

const useTextTruncation = (text: string, maxLength: number): string => {
  const [truncatedTxt, setTruncatedTxt] = useState<string>(text);

  useEffect(() => {
    if (text.length >= maxLength) {
      const lastSpaceIndex = text.lastIndexOf(" ", maxLength);
      setTruncatedTxt(text.slice(0, lastSpaceIndex) + " ...");
    } else {
      setTruncatedTxt(text);
    }
  }, [text, maxLength, setTruncatedTxt]);

  return truncatedTxt;
};

export default useTextTruncation;

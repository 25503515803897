import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import NotFoundIcon from "../components/Icons/NotFoundIcon";
import Navbar from "../components/Navbar";
import usePageReset from "../hooks/usePageReset";

export default function ErrorPage() {
  const pageReset = usePageReset();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Cultiv | Error</title>
      </Helmet>
      <main className="h-full min-h-screen w-screen flex flex-col relative overflow-x-hidden">
        <Navbar />
        <div className="flex flex-col gap-4 w-full h-full relative px-6 sm:px-8 lg:px-10 xl:px-20">
          <div className="flex flex-col md:flex-row pt-10">
            <div className="sm:z-10 sm:w-5/12 lg:w-7/12 2xl:w-3/5 3xl:w-4/6 flex flex-col md:pt-24 lg:pl-28 gap-3 sm:gap-5 lg:gap-12">
              <h2 className="text-4xl lg:text-6xl font-bold">Whoops!</h2>
              <h1 className="sm:pr-32 lg:pr-0 text-6xl lg:text-8xl xl:text-[140px] font-bold text-cultiv-yellow text-center">
                404
              </h1>
              <h3 className="text-xl sm:text-3xl lg:text-4xl font-bold text-center lg:text-end">
                Page Not Found
              </h3>
            </div>
            <div className="max-w-fit w-full h-1/3 sm:w-11/12 sm:h-1/2 md:w-1/2 lg:w-auto md:h-1/5 lg:h-auto lg:pt-10">
              <NotFoundIcon />
            </div>
          </div>
          <p className="z-10 text-sm md:text-2xl self-center text-center lg:pb-6">
            The page you are looking for either expired or doesn't exist anymore
          </p>
          <Link
            to={`/`}
            className="py-2 px-4 min-w-fit self-center bg-black hover:bg-cultiv-yellow flex justify-center items-center text-xl font-semibold text-gray-50 hover:text-black"
          >
            Go back to Home
          </Link>
        </div>
        <div className="pt-6 lg:pt-10">
          <Footer />
        </div>
      </main>
    </>
  );
}

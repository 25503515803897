import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Person } from "../schema";

const useCult = (member: string): [Person[], boolean] => {
  const [cult, setCult] = useState<Person[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    let cancelRequest = false;
    const fetchCultData = async () => {
      try {
        const response = await fetch(
          import.meta.env.VITE_GET_CULT_MEMBERS + `role=${member}&sort=name`
        );
        if (!response.ok) {
          throw new Error(`error code: ${response.status}, Group not found or doesn't exist`);
        }
        if (!cancelRequest) {
          const { data } = await response.json();
          setCult(data);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        navigate(`/404`);
      }
    };
    fetchCultData();
    return () => {
      cancelRequest = true;
    };
  }, [member]);

  return [cult, isLoading];
};

export default useCult;

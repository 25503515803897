import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import Image from "../components/Image";
import MarkDown from "../components/MarkDown";
import usePageReset from "../hooks/usePageReset";
import useProgram from "../hooks/useProgram";

export default function ProgramPage() {
  const { programID } = useParams();
  const programDetails = useProgram(programID ? programID : "/404");
  const pageReset = usePageReset();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="cultiv program incubation incubator accelerator startup" />
        <meta name="description" content="A program implemented by Cultiv llc" />
        <meta name="robots" content="index, follow" />
        <title>Cultiv | {`${programDetails ? programDetails.title : "Program"}`}</title>
      </Helmet>
      <div className="w-full grid grid-cols-12 lg:gap-7.5 gap-3 lg:px-20 px-6 pt-6">
        <h1 className="lg:text-4xl text-xl font-bold col-span-12 lg:pb-8 pb-3 border-b-2 border-gray-400 text-center">
          {programDetails?.title}
        </h1>

        <Image
          src={programDetails?.program_cover}
          height={630}
          className="lg:col-start-2 lg:col-span-10 col-span-12"
          alt={programDetails?.title}
        />

        {programDetails?.description && (
          <MarkDown
            articleBody={programDetails.description}
            prosePadding="lg:col-start-2 lg:col-span-10 col-span-12"
          />
        )}
        {programDetails?.apply_link && (
          <div className="col-span-12 place-self-center">
            <div className="md:text-xl text-center ease-in-out duration-300 text-base bg-black text-white md:font-bold font-semibold border-white md:w-60 w-32 py-2 pt-3 hover:bg-cultiv-yellow hover:text-black">
              <a href={programDetails.apply_link}>Apply now</a>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Person } from "../schema";

export interface TeamArrays {
  CEO: Person[];
  STUDIO: Person[];
}

const useTeam = (): [Person[], boolean] => {
  const [team, setTeam] = useState<Person[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  useEffect(() => {
    let cancelRequest = false;
    const fetchCultivTeam = async () => {
      try {
        const response = await fetch(import.meta.env.VITE_GET_CULTIV_TEAM_SORTED);
        if (!response.ok) {
          throw new Error(response.status + response.statusText);
        }
        if (!cancelRequest) {
          const { data } = await response.json();
          const { ceo, studio, reactor, admin } = data;
          setTeam([...ceo, ...studio, ...reactor, ...admin]);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        navigate(`/404`);
      }
    };
    fetchCultivTeam();
    return () => {
      cancelRequest = true;
    };
  }, [setIsLoading, setTeam]);
  return [team, isLoading];
};

export default useTeam;

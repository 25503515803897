import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Program } from "../schema";

const useProgram = (programName: string) => {
  const [program, setProgram] = useState<Program>();
  const navigate = useNavigate();
  useEffect(() => {
    let cancelRequest = false;
    const getProgram = async () => {
      try {
        const response = await fetch(import.meta.env.VITE_GET_PROGRAM + programName, {
          method: "GET",
        });

        if (!response.ok)
          throw new Error(
            response.status +
              response.statusText +
              "An error has ocurred while fetching the program"
          );

        if (!cancelRequest) {
          const { data } = await response.json();
          setProgram(data);
        }
      } catch (error) {
        console.log(error);
        navigate("/404");
      }
    };
    getProgram();
    return () => {
      cancelRequest = true;
    };
  }, [programName]);
  return program;
};

export default useProgram;

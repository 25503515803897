import { CultivContact, FooterRedirectLink } from "../schema";

export const contactsName: CultivContact[] = [
  { contact: "CULTIV GmbH, Switzerland" },
  { contact: "CULTIVnet Lda, Portugal" },
  { contact: "CULTIV Business LLC, Egypt" },
  { contact: "CULTIV LLC, USA" },
];
export const contactsLocation: CultivContact[] = [
  { contact: "8 Untere Roostmatt, 6300 Zug" },
  { contact: "132 Rua Dom Afonso Henriques, Cortes 4950-854" },
  { contact: "124 Othman Ibn Afan Street, Heliopolis, Cairo 11361" },
  { contact: "16192 Coastal Highway, Lewes, DE 19958" },
];

export const contacts = contactsName.map(
  (contact, index) => [contact, contactsLocation[index]] as [CultivContact, CultivContact]
);

export const footerLinks: FooterRedirectLink[] = [
  { title: "Home", pathname: "/" },
  { title: "About", pathname: "/about" },
  { title: "Cultivators", pathname: "/cultivators" },
  { title: "Bureau", pathname: "/bureau" },
  { title: "Careers", pathname: "/careers" },
];

import { useNavigate } from "react-router";
import Image from "./Image";

export interface Props {
  imgSrc: string;
  legend?: string;
  type: string;
  slug: string;
  title: string;
  isInside: boolean;
  isDirectory?: boolean;
}

export default function SliderPanel({
  imgSrc,
  legend,
  type,
  slug,
  title,
  isInside,
  isDirectory,
}: Props) {
  const navigate = useNavigate();

  return (
    // <div className="h-[20rem] lg:h-[27rem] 2xl:h-[41rem] w-full cursor-pointer">
    <div className="cursor-pointer aspect-video w-full h-full">
      <button
        onClick={() => {
          isDirectory ? navigate(`/${slug}`) : navigate(`/culture/${type}/${slug}`);
        }}
        className="w-full h-full"
      >
        <Image alt={title} src={imgSrc} className="w-full object-cover" />
      </button>
      <div
        className={`absolute top-0 w-full h-full -z-9 xl:p-4 p-2 bg-gradient-to-br from-gray-900 via-transparent text-left`}
        onClick={() => {
          isDirectory ? navigate(`/${slug}`) : navigate(`/culture/${type}/${slug}`);
        }}
      >
        <h1 className="text-[#faf8f4] font-bold 2xl:text-3xl xl:text-xl md:text-base h-full">
          {title}
        </h1>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Person } from "../schema";

const usePersonProfile = (ideaName: string): [Person, boolean] => {
  const [person, setPerson] = useState<Person>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const location = useLocation();
  const navigate = useNavigate();
  const hasVerifyParam = new URLSearchParams(location.search).has("verf") || false;

  useEffect(() => {
    let cancelRequest = false;
    const fetchPersonData = async () => {
      try {
        if (hasVerifyParam) {
          const response = await fetch(
            import.meta.env.VITE_GET_PERSON_CULTIV_ID + `cultiv_id=${ideaName}`,
            { method: "POST" }
          );
          if (!response.ok) {
            throw new Error(
              `error code: ${response.status}, The person is not found or the Cultiv ID is incorrect`
            );
          }
          if (!cancelRequest) {
            const { user } = await response.json();
            setPerson(user);
            window.history.replaceState(null, "New Page Title", `/cult/profile/${user?.idea_name}`);
            setIsLoading(false);
          }
        } else {
          const response = await fetch(import.meta.env.VITE_GET_PERSON_PROFILE + ideaName);
          if (!response.ok) {
            throw new Error(
              `error code: ${response.status}, The person is not found or the idea-name is incorrect`
            );
          }
          if (!cancelRequest) {
            const { user } = await response.json();
            setPerson(user);
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
        navigate(`/404`);
      }
    };
    fetchPersonData();
    return () => {
      cancelRequest = true;
    };
  }, [ideaName]);
  return [person as Person, isLoading];
};

export default usePersonProfile;

import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import structuredData from "../assets/structuredText";
import HomeFilter from "../components/HomeFilter";
import MemberPopup from "../components/MemberPopup";
import MembersButtonsGrid from "../components/MembersButtonsGrid";
import Slider from "../components/Slider";
import usePageReset from "../hooks/usePageReset";
import { RootState } from "../redux/configureStore";
import { getCurrentPage } from "../redux/features/pagePaginationSlice";

export default function Home() {
  const openMemberModal = useSelector((state: RootState) => state.memberModal.open);
  const currentPage = useSelector((state: RootState) =>
    getCurrentPage(state.pagePagination, "home")
  );
  const pageReset = usePageReset();

  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="cultiv entrepreneur startup members collective company consultation business growth idea"
        />
        <meta
          name="description"
          content="Cultiv LLC is an Egyptian company that consist of a collective of companies, Cultiv has been a pioneer in the ecosystem of the Egyptian market since 2011"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:url" content="https://staging.cultiv.llc" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          itemProp="image"
          content="https://staging.cultiv.llc/images/metaimg.jpg"
        />
        <link
          rel="image_src"
          itemProp="image"
          href="https://staging.cultiv.llc/images/metaimg.jpg"
        />
        <meta property="og:description" content="The staging platform of cultiv.llc website" />
        <meta property="og:site_name" content="Cultiv LLC" />
        <meta property="og:title" content="Staging Cultiv LLC" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://staging.cultiv.llc" />
        <meta property="twitter:title" content="Staging Cultiv" />
        <meta property="twitter:description" content="This the cultiv staging website" />
        <meta property="twitter:image" content="https://staging.cultiv.llc/images/metaimg.jpg" />
        <link rel="canonical" href="https://staging.cultiv.llc" />
        <script type="application/ld+json">{structuredData}</script>
        <title>Cultiv</title>
      </Helmet>
      <div className="w-full flex flex-col justify-center gap-5 lg:px-20 lg:mt-8">
        <div className="w-full grid grid-cols-4 lg:grid-cols-12 gap-4 lg:gap-8">
          <div className="col-span-4 lg:col-span-8">
            <Slider />
          </div>
          <div className="flex flex-col gap-4 col-span-4 lg:col-span-4 px-6 lg:px-0 w-full">
            <h1 className="xl:text-3xl md:text-2xl text-xl border-b-2 border-gray-400 w-full text-start pb-2">
              Collective Members
            </h1>
            <MembersButtonsGrid />
          </div>
        </div>
        {openMemberModal && <MemberPopup />}
        <div className="w-full flex flex-col gap-5 px-6 lg:px-0 justify-center items-center">
          <HomeFilter page={currentPage} pageId="home" />
        </div>
      </div>
    </>
  );
}

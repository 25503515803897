export interface Props {
  color: string;
  className?: string;
}

function RightChevron({ color, className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={`${color}`}
        strokeLinecap="round"
        strokeWidth="2"
        d="M8.5 5c1 2 4 5.5 7 7-4.5 2.5-6.38 5.924-7 7"
      ></path>
    </svg>
  );
}

export default RightChevron;
